import { useFormik } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useHistory, withRouter } from "react-router-dom";
import Select from "react-select";
import { Col, Input, Row } from "reactstrap";
import {
  addNewTarget,
  getOrgUser,
  getTargetAchievement,
  getTargetValue,
  getUsers,
  modifyTargetData,
  storeUserData,
  updateTarget,
} from "../../store/actions";
import CardComponent from "../Layout/CardComponent";
import CustomTable from "../Layout/CustomTable";
import InnerLayer from "../Layout/InnerLayer";
import NoTableData from "../Layout/NoTableData";
import AchievementChart from "./AchievementChart";

const tableHead = [
  "No.",
  "User Name",
  "User Role",
  "Org",
  "Email",
  "Phone No.",
  "Action",
  // "View Details",
  // "View Details",
  // "Action",
];
const initialValues = {
  name: "",
};

const Users = () => {
  let {
    userData,
    loading,
    authtoken,
    targetData,
    userrole,
    orgUserId,
    orgUser,
    targetAchievement,
  } = useSelector((state) => ({
    loading: state.UserReducer.isLoading,
    userData: state?.UserReducer?.users,
    targetData: state.TargetReducer.targetData,
    targetAchievement: state.TargetReducer.targetAchievement,
    authtoken: state.Login.token,
    userrole: state.Login.userrole,
    orgUserId: state.Login.warehouse,
    orgUser: state?.UserReducer?.orgUser,
  }));

  const [listUser, setListUser] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [handleList, setHandleList] = useState(10);
  const [userRole, setUserRole] = useState("BP");
  const [orgId, setOrgId] = useState("All");

  const [userId, setUserId] = useState("");
  let [getUserId, setGetUserId] = useState("");
  let [updateFromDate, setUpateFromDate] = useState({});
  let [days, setDays] = useState(0);
  const years = [
    {
      name: "2022",
      value: "2022",
    },
    {
      name: "2023",
      value: "2023",
    },
    {
      name: "2024",
      value: "2024",
    },
    {
      name: "2025",
      value: "2025",
    },
    {
      name: "2026",
      value: "2026",
    },
    {
      name: "2027",
      value: "2027",
    },
    {
      name: "2028",
      value: "2028",
    },
    {
      name: "2029",
      value: "2029",
    },
    {
      name: "2030",
      value: "2030",
    },
  ];

  let [target, setTarget] = useState([
    {
      month: "01-" + new Date().getFullYear(),
      actualTarget: 0,
    },
    {
      month: "02-" + new Date().getFullYear(),
      actualTarget: 0,
    },
    {
      month: "03-" + new Date().getFullYear(),
      actualTarget: "1",
    },
    {
      month: "04-" + new Date().getFullYear(),
      actualTarget: "1",
    },
    {
      month: "05-" + new Date().getFullYear(),
      actualTarget: 0,
    },
    {
      month: "06-" + new Date().getFullYear(),
      actualTarget: 0,
    },
    {
      month: "07-" + new Date().getFullYear(),
      actualTarget: 0,
    },
    {
      month: "08-" + new Date().getFullYear(),
      actualTarget: 0,
    },
    {
      month: "09-" + new Date().getFullYear(),
      actualTarget: 0,
    },
    {
      month: "10-" + new Date().getFullYear(),
      actualTarget: 0,
    },
    {
      month: "11-" + new Date().getFullYear(),
      actualTarget: 0,
    },
    {
      month: "12-" + new Date().getFullYear(),
      actualTarget: 0,
    },
  ]);

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const targetArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  let dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getOrgUser(authtoken, "ORG", orgId, handleList, currentPage));
  }, []);

  useEffect(() => {
    userrole == "ADMIN" ? (
      dispatch(getUsers(authtoken, userRole, orgId, handleList, currentPage))
    ) : userrole == "ORG" ? (
      dispatch(
        getUsers(authtoken, userRole, orgUserId, handleList, currentPage)
      )
    ) : (
      <></>
    );
  }, [orgId, userRole, handleList, currentPage]);

  let totalPageNumber = Math.ceil(
    userData?.data?.data?.totalLength / handleList
  );

  const handleEditUser = (data) => {
    dispatch(storeUserData("singleUser", data));
    history.push("/edit-user/" + data._id);
  };
  const viewCustomer = (index) => {
    setListUser(
      listUser?.map((data, i) => {
        if (i === index) {
          data.toggle = !data.toggle;
        } else {
          data.toggle = false;
        }
        return data;
      })
    );
  };

  const breadcrumbItems = [
    {
      title: "Target Set",
      link: "#",
    },
    {
      title: "User List",
      link: "#",
    },
  ];
  //modal
  const [open, setOpen] = useState(false);
  const [modalSelect, setModalSelect] = useState(true);
  const [modalSelectView, setModalSelectView] = useState(true);
  const [year, setYear] = useState(new Date().getFullYear());

  const onOpenAddModal = (data) => {
    dispatch(getTargetAchievement(authtoken, data._id, year));
    setModalSelect(true);
    setOpen(true);
  };
  const onOpenEditModal = (data) => {
    dispatch(getTargetValue(authtoken, data._id));
    setModalSelect(false);
    setModalSelectView(false);
    setOpen(true);
  };

  // useEffect(() => {
  //   dispatch(getTargetAchievement(authtoken, userId, year));
  // }, [setUserId, year]);
  const onOpenViewModal = (data) => {
    dispatch(getTargetAchievement(authtoken, data._id, year));
    setModalSelect(false);
    setModalSelectView(true);
    setOpen(true);
  };

  const handleYear = (value) => {
    setYear(value?.value);
    if (value) {
      dispatch(getTargetAchievement(authtoken, userId, year));
    } else {
      dispatch(getTargetAchievement(authtoken, userId, year));
    }
  };

  const onCloseModal = () => setOpen(false);
  //formik
  const onSubmit = (values) => {
    const data = { ...values, arr };

    let obj = {};
  };
  const RouteAdd = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit,
  });
  //date
  const [fromDate, setFromDate] = useState(
    moment(new Date(Date.now())).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState("");

  const onFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const arr = [];
  const handleSetTarget = (targetAmount, serial) => {
    let serialMonth = serial + 1;
    let month = serialMonth + "-" + new Date().getFullYear();

    setTarget({
      ...target,
      [serial]: {
        month:
          serialMonth / 2 < 5
            ? "0" + serialMonth + "-" + new Date().getFullYear()
            : serialMonth + "-" + new Date().getFullYear(),
        targetAmount: targetAmount ? targetAmount : 0,
      },
    });
  };
  const renderTooltipView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View
    </Tooltip>
  );
  const renderTooltipEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Update
    </Tooltip>
  );
  const renderTooltipAdd = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add
    </Tooltip>
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    let arr = [];
    Object.keys(target).map((v, i) => {
      arr.push({
        // user: target[v].userId,
        user: userId,
        month: target[v].month,
        year: new Date().getFullYear(),
        actualTarget: parseInt(
          target[v].targetAmount ? target[v].targetAmount : 0
        ),
      });
    });
    setOpen(false);

    dispatch(addNewTarget(arr, history, authtoken));
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    let arr = [];
    targetData?.map((v, i) => {
      arr.push({
        _id: v._id,
        user: v.user._id,
        month: v.month,
        actualTarget: v.actualTarget,
      });
    });
    setOpen(false);

    dispatch(updateTarget(arr, history, authtoken));
  };

  const handleUpdateTarget = (index, target, month, data) => {
    dispatch(modifyTargetData(index, target, month, targetData));
    // targetData[index] = {
    //   ...targetData[index],
    //   date: month,
    //   actualTarget: target,
    // };
  };
  const handleListData = (e) => {
    setHandleList(e.target.value);
  };
  const handleOrgFilter = (value) => {
    if (value) {
      setOrgId(value._id);
    } else {
      setOrgId("All");
    }
  };
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Target Set"}
        breadcrumbItems={breadcrumbItems}
      >
        {/* first Row */}

        <Row>
          <CardComponent className="user_table_card">
            {/* <TableHeader
              handleSearchFilter={() => {}}
              handleSelectFilter={(e) => {
                setHandleList(e.target.value);
              }}
              data={[10, 20, 50, 100]}
              selectValue={handleList}
              title=""
              headerSearchOption="yes"
            /> */}
            <Row className="mb-3 table-header-padding">
              <div className="d-flex d-flex-blockCustom justify-content-end">
                {userrole == "ADMIN" ? (
                  <Col className="ms-2" md={2}>
                    <Select
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Organization"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={orgUser?.data?.data?.users}
                      onChange={handleOrgFilter}
                    />
                  </Col>
                ) : (
                  <></>
                )}

                <Col className="ms-2" md={2}>
                  <Input type="select" onChange={handleListData}>
                    {/* <option defaultValue>Select...</option> */}
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Input>
                </Col>
              </div>
            </Row>
            {/* table */}
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={userData?.data?.data?.users}
              tableHead={tableHead}
              pageNo={totalPageNumber}
              setCurrentPage={setCurrentPage}
              isPagination
            >
              {userData?.data?.data?.users?.length > 0 ? (
                userData?.data?.data?.users?.map((data, idx) => (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>{data?.name}</td>
                    <td>{data?.role}</td>
                    <td>{data?.org?.name}</td>
                    <td>{data?.email}</td>
                    <td>{data.phoneNumber}</td>
                    <td>
                      <div className="d-flex justify-content-start align-items-center">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipAdd}
                        >
                          <button
                            className="btn btn-info btn-sm me-2"
                            style={{ borderRadius: "10px" }}
                            onClick={() => {
                              setUserId(data._id);
                              onOpenAddModal(data);
                              setDays(0);
                            }}
                          >
                            <i
                              className="bx bx-plus
"
                            ></i>
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipEdit}
                        >
                          <button
                            className="btn btn-outline-info btn-sm me-2"
                            style={{ borderRadius: "10px" }}
                            onClick={() => onOpenEditModal(data)}
                          >
                            <i className="bx bx-edit"></i>
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipView}
                        >
                          <button
                            className="btn btn-outline-info btn-sm"
                            style={{ borderRadius: "10px" }}
                            onClick={() => {
                              setUserId(data._id);
                              onOpenViewModal(data);
                            }}
                          >
                            <i className="bx bx-show"></i>
                          </button>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <NoTableData
                  colSpan="9"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>
                    No data here
                    {/* <Link
                        to="/add-user"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        create User
                      </Link> */}
                  </span>
                </NoTableData>
              )}
            </CustomTable>
            {/* table end */}
          </CardComponent>
          {/* component end */}
        </Row>
        <div>
          {/* <button onClick={onOpenModal}>Open modal</button> */}
          {loading ? (
            <></>
          ) : modalSelect ? (
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames={{
                overlay: "customOverlay",
                modal: "modal-main-wrap-fgf",
              }}
            >
              <Container>
                <div className="popup-main-area-data-site-information">
                  <div className="site-info-popup-title">
                    <h4>Add Target</h4>
                  </div>
                  <Row>
                    <Form className="needs-validation" onSubmit={handleSubmit}>
                      <Container>
                        <Row>
                          {targetAchievement?.data?.data?.target?.length ==
                          0 ? (
                            targetArr?.map((data, idx) => (
                              <>
                                <Col lg={6}>
                                  <Row>
                                    <Col lg={12}>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formTarget"
                                      >
                                        <Form.Label>Month</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Target"
                                          defaultValue={
                                            months[idx] +
                                            " " +
                                            new Date().getFullYear()
                                          }
                                          readOnly
                                          // value={calculateDate(v)}
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={6}>
                                  <Row>
                                    <Col lg={12}>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formTarget"
                                      >
                                        <Form.Label>Type Target</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Target"
                                          onChange={(e) =>
                                            handleSetTarget(e.target.value, idx)
                                          }
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </Col>
                              </>
                            ))
                          ) : (
                            <h4 className="text-center mb-4 text-danger">
                              The Target is already added
                            </h4>
                          )}
                        </Row>
                        {targetAchievement?.data?.data?.target?.length == 0 ? (
                          <div className="d-flex justify-content-center">
                            <input
                              type="submit"
                              value={"Submit"}
                              className="btn button mt-4"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Container>
                    </Form>
                    {/* <Col lg={6}>
                      <div>ADD</div>
                    </Col> */}
                  </Row>
                </div>
              </Container>
            </Modal>
          ) : modalSelectView ? (
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames={{
                overlay: "customOverlay",
                modal: "modal-main-wrap-fgf",
              }}
            >
              <Container>
                <div className="popup-main-area-data-site-information">
                  <div
                    className="site-info-popup-title"
                    style={{ marginBottom: "0px" }}
                  >
                    <h4>Target Achievement</h4>
                  </div>
                  <Row className="mb-3 table-header-padding">
                    <div className="d-flex d-flex-blockCustom justify-content-center">
                      <Col md={4} className="custom-bottom-margin mb-4">
                        <Select
                          name="year"
                          className="me-2"
                          classNamePrefix="select2-selection"
                          placeholder="Select Year"
                          cacheOptions
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.value}
                          isClearable
                          options={years}
                          onChange={handleYear}
                        />
                      </Col>
                    </div>
                    <AchievementChart userId={userId} year={year} />
                  </Row>
                  <Row>
                    <Container>
                      <Table className="table table-hover mb-0 table-centered table-nowrap mb-5">
                        <thead className="table-light">
                          <tr>
                            <th>No.</th>
                            <th>Year</th>
                            <th>Month</th>
                            <th>Target</th>
                            <th>Achievement Target</th>
                            <th>Remaining Target</th>
                          </tr>
                        </thead>

                        <tbody>
                          {/* { targetAchievement ?.data?.data?.target?.length > 0 ? (
                             targetAchievement ?.data?.data?.target?.map((data, idx) => (
                              targetAchievement ?.data?.data?.target?. */}
                          {targetAchievement?.data?.data?.target?.length > 0 ? (
                            targetAchievement?.data?.data?.target?.map(
                              (data, idx) => (
                                <tr>
                                  <td>{idx + 1}</td>
                                  <td>{data?.year}</td>
                                  <td>{data?.month}</td>
                                  <td>{data?.actualTarget}</td>
                                  <td>
                                    {data?.actualTarget - data?.achiveTarget} (
                                    {data?.actualTarget > 0
                                      ? (
                                          (data?.achiveTarget /
                                            data?.actualTarget) *
                                          100
                                        ).toFixed(2)
                                      : 0}
                                    %)
                                  </td>
                                  <td>
                                    {data?.actualTarget - data?.achiveTarget}
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan={6}>No Data Here</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Container>
                  </Row>
                </div>
              </Container>
            </Modal>
          ) : (
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames={{
                overlay: "customOverlay",
                modal: "modal-main-wrap-fgf",
              }}
            >
              <Container>
                <div className="popup-main-area-data-site-information">
                  <div className="site-info-popup-title">
                    <h3>Edit Target</h3>
                  </div>
                  <Row>
                    <Form className="needs-validation" onSubmit={handleUpdate}>
                      <Container>
                        <Row>
                          {targetData?.length > 0 ? (
                            targetData?.map((data, idx) => (
                              <>
                                <Col lg={6}>
                                  <Row>
                                    <Col lg={12}>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formTarget"
                                      >
                                        <Form.Label>Month</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Target"
                                          defaultValue={
                                            months[idx] +
                                            " " +
                                            new Date().getFullYear()
                                          }
                                          readOnly
                                          // value={calculateDate(v)}
                                        />
                                      </Form.Group>
                                      {/* {JSON.stringify(
                                        targetData[idx].actualTarget
                                      )} */}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={6}>
                                  <Row>
                                    <Col lg={12}>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formTarget"
                                      >
                                        <Form.Label>Type Target</Form.Label>
                                        <Form.Control
                                          type="number"
                                          placeholder="Target"
                                          value={targetData[idx]?.actualTarget}
                                          onChange={(e) =>
                                            handleUpdateTarget(
                                              idx,
                                              e.target.value,
                                              data?.month,
                                              data
                                            )
                                          }
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </Col>
                              </>
                            ))
                          ) : (
                            <></>
                          )}
                        </Row>{" "}
                        <div className="d-flex justify-content-center">
                          <input
                            type="submit"
                            value={"Update"}
                            className="btn button mt-4"
                          />
                        </div>
                      </Container>
                    </Form>
                  </Row>
                </div>
              </Container>
            </Modal>
          )}
        </div>
      </InnerLayer>
    </React.Fragment>
  );
};

Users.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(Users);
