import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import StepTwo from './StepTwo';

const EditQuestionModal = ({ isOpen, toggle }) => {
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: '500px' }}>
        <ModalBody>
          <StepTwo edit isOpen={isOpen} toggle={toggle} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EditQuestionModal;
