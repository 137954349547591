import React, { useState } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import icon1 from "./icon/dashboard.svg";
import profileCircle from "./icon/profile-circle.svg";
import question from "./icon/question.svg";
import survey from "./icon/survey.svg";
import target from "./icon/target.svg";
import attendance from "./icon/tick-square.svg";
const NavbarMainLink = () => {
  const [dropClose, setDropClose] = useState(false);
  // const handleDropClose = () =>{
  //   setDropClose()
  // }
  const { userrole } = useSelector((state) => ({
    userrole: state.Login.userrole,
  }));

  return (
    <div className="navbar-item-main-wrap-nav-items">
      <h3>Menu</h3>

      <ul>
        <li onClick={() => setDropClose(!dropClose)}>
          <NavLink
            exact
            activeClassName="nav-link-selected"
            to="/admin-dashboard"
          >
            <img src={icon1} alt="" />
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li onClick={() => setDropClose(!dropClose)}>
          <NavLink exact activeClassName="nav-link-selected" to="/users">
            <img src={profileCircle} alt="" />
            <span>Users</span>
          </NavLink>
        </li>
        <li onClick={() => setDropClose(!dropClose)}>
          <NavLink exact activeClassName="nav-link-selected" to="/question">
            <img src={question} alt="" />
            <span>Activation</span>
          </NavLink>
        </li>
        <li onClick={() => setDropClose(!dropClose)}>
          <NavLink exact activeClassName="nav-link-selected" to="/target-set">
            <img src={target} alt="" />
            <span>Target Set</span>
          </NavLink>
        </li>
        <li onClick={() => setDropClose(!dropClose)}>
          <NavLink exact activeClassName="nav-link-selected" to="/survey">
            <img src={survey} alt="" />
            <span>Survey</span>
          </NavLink>
        </li>
        <li onClick={() => setDropClose(!dropClose)}>
          <NavLink exact activeClassName="nav-link-selected" to="/attendance">
            <img src={attendance} alt="" />
            <span>Attendance</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default NavbarMainLink;
