import {
  ADD_NEW_TARGET,
  ADD_TARGET_FAIL,
  ADD_TARGET_SUCCESS,
  GET_TARGET_ACHIEVEMENT,
  GET_TARGET_ACHIEVEMENT_FAIL,
  GET_TARGET_ACHIEVEMENT_SUCCESS,
  GET_TARGET_VALUE,
  GET_TARGET_VALUE_SUCCESS,
  MODIFY_TARGET_DATA,
  MODIFY_TARGET_DATA_SUCCESS,
  UPDATE_TARGET,
  UPDATE_TARGET_FAIL,
  UPDATE_TARGET_SUCCESS,
} from "./actionTypes";

//TARGET
export const getTargetValue = (authtoken, userId) => ({
  type: GET_TARGET_VALUE,
  payload: { authtoken, userId },
});

export const getTargetValueSuccess = (data) => ({
  type: GET_TARGET_VALUE_SUCCESS,
  payload: { data },
});

export const addNewTarget = (data, history, authtoken) => ({
  type: ADD_NEW_TARGET,
  payload: { data, history, authtoken },
});
export const addTargetSuccess = (data) => ({
  type: ADD_TARGET_SUCCESS,
  payload: data,
});

export const addTargetFail = (error) => ({
  type: ADD_TARGET_FAIL,
  payload: error,
});

export const updateTarget = (data, history, authtoken) => ({
  type: UPDATE_TARGET,
  payload: { data, history, authtoken },
});

export const updateTargetSuccess = (data) => ({
  type: UPDATE_TARGET_SUCCESS,
  payload: { data },
});

export const updateTargetFail = (error) => ({
  type: UPDATE_TARGET_FAIL,
  payload: error,
});

export const getTargetAchievement = (authtoken, userId, year) => ({
  type: GET_TARGET_ACHIEVEMENT,
  payload: {
    authtoken,
    userId,
    year,
  },
});

export const getTargetAchievementSuccess = (data) => ({
  type: GET_TARGET_ACHIEVEMENT_SUCCESS,
  payload: { data },
});

export const getTargetAchievementFail = (error) => ({
  type: GET_TARGET_ACHIEVEMENT_FAIL,
  payload: error,
});

export const modifyTargetData = (index, target, month, data) => ({
  type: MODIFY_TARGET_DATA,
  payload: { index, target, month, data },
});

export const modifyTargetDataSuccess = (data) => ({
  type: MODIFY_TARGET_DATA_SUCCESS,
  payload: { data },
});
