import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { Row, Spinner } from 'reactstrap';
import {
  getActivationDetails,
  getActivationDetailsSuccess,
  getUsers,
} from '../../store/actions';
import { toCapitalize } from '../Helpers/Custom/Capitalize';
import CustomTable from '../Layout/CustomTable';
import InnerLayer from '../Layout/InnerLayer';
import NoTableData from '../Layout/NoTableData';
import EditQuestionModal from './EditQuestionModal';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const tableHead = ['Sl. No.', 'Question', 'Type', 'Action'];
const renderTooltipView = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    View Details
  </Tooltip>
);

const AddQuestion = ({ history, edit }) => {
  const [open, setOpen] = React.useState('');
  const { id } = useParams();
  let dispatch = useDispatch();
  const { activationDetails, loading, authToken, users } = useSelector(
    (store) => ({
      activations: store.ActivationReducer.activations,
      questionDetails: store.ActivationReducer.questionDetails,
      loading: store.ActivationReducer.loading,
      activationDetails: store.ActivationReducer.activationDetails,
      adding: store.ActivationReducer.adding,
      editActivationInfo: store.ActivationReducer.editActivationInfo,
      authToken: store.Login.token,
      users: store?.UserReducer?.users,
    })
  );

  console.log(`activationDetailsLoading,`, activationDetails);
  useEffect(() => {
    dispatch(getUsers(authToken, 'ORG', 'All', 0, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (edit) {
      dispatch(getActivationDetails(authToken, id));
    }
    return () => {
      dispatch(getActivationDetailsSuccess({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('users', users);

  return (
    <React.Fragment>
      <InnerLayer
        title="Activation"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={'#'}
        mainTitle={'Activation'}
        subTitle={'Create'}
      >
        <Row>
          <div className="col-md-5">
            <div className="card">
              <div className="card-body">
                <h4 className="mt-4 mb-3" style={{ color: '#058DE0' }}>
                  {edit ? 'Edit activation' : 'Activation Create'}
                </h4>
                {activationDetails?.activation?._id && !edit ? (
                  <StepTwo />
                ) : (
                  <StepOne edit={edit} />
                )}
              </div>
            </div>
          </div>
          {open ? (
            <EditQuestionModal isOpen={open} toggle={() => setOpen('')} />
          ) : null}
          <div className="col-md-7">
            <div className="card">
              <div className="card-body">
                <CustomTable
                  paginationClass="paginationContainer text-right"
                  data={[]}
                  // pageNo={totalPageNumber}
                  tableHead={tableHead}
                  // setCurrentPage={setCurrentPage}
                  // isPagination
                >
                  {loading ? (
                    <tr style={{ width: '100%' }} colSpan="4">
                      <div
                        className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                        style={{ width: '100%' }}
                      >
                        <div>
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </div>
                    </tr>
                  ) : activationDetails?.questions?.length > 0 ? (
                    activationDetails?.questions?.map((data, idx) => (
                      <tr key={idx}>
                        <th scope="row" style={{ paddingLeft: '25px' }}>
                          {idx + 1}
                        </th>
                        <td>{data?.name}</td>
                        <td>{toCapitalize(data?.questionType)}</td>
                        <td>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipView}
                          >
                            <button
                              className="btn btn-outline-info btn-sm"
                              style={{ borderRadius: '10px' }}
                              onClick={() => (edit ? setOpen(data?._id) : {})}
                            >
                              <i className="bx bx-show mt-1"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoTableData
                      colSpan="4"
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: '300px', width: `100%` }}
                    >
                      <span>No data Available</span>
                    </NoTableData>
                  )}
                </CustomTable>
                {/* table end */}
              </div>
            </div>
          </div>
        </Row>
      </InnerLayer>
    </React.Fragment>
  );
};

AddQuestion.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(AddQuestion);
