import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  addUserData,
  getUserData,
  getUserDetailsData,
  updateUserData,
} from '../../helpers/api_helper.js';
import {
  addUserFail,
  addUserSuccess,
  getOrgUserFail,
  getOrgUserSuccess,
  getUserDetailsFail,
  getUserDetailsSuccess,
  getUsers,
  getUsersFail,
  getUsersSuccess,
  updateUserFail,
} from './actions';
import {
  ADD_USER,
  GET_ALL_USER,
  GET_ORG_USER,
  GET_USER_DETAILS,
  UPDATE_USER,
} from './actionTypes';

function* onAddNewUser({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(addUserData, data, authtoken);
    console.log('hi');
    console.log(response);
    yield put(addUserSuccess(response));
    toast('🦄 User added successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(getUsers(authtoken, 'All', 10, 1));
  } catch (error) {
    if (!error.response) {
      toast('🦄 User added successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //   history.push("/details-product")
    } else {
      let message = error.response.data.message;
      yield put(addUserFail(message));
      // console.log("hi",message);
      toast.error(message);
    }
  }
}

function* fetchUsers({
  payload: { authtoken, role, orgId, list, currentPage },
}) {
  try {
    const response = yield call(
      getUserData,
      authtoken,
      role,
      orgId,
      list,
      currentPage
    );
    yield put(getUsersSuccess(response));
    console.log('line 15', response);
  } catch (error) {
    yield put(getUsersFail(error));
    console.log('hi');
  }
}
function* fetchOrgUser({
  payload: { authtoken, role, orgId, list, currentPage },
}) {
  try {
    const response = yield call(
      getUserData,
      authtoken,
      role,
      orgId,
      list,
      currentPage
    );
    yield put(getOrgUserSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getOrgUserFail(error));
    console.log('hi');
  }
}
function* fetchUserDetails({ payload: { authtoken, id } }) {
  try {
    const response = yield call(getUserDetailsData, authtoken, id);
    yield put(getUserDetailsSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getUserDetailsFail(error));
    console.log('hi');
  }
}
//EDIT USER
function* onUpdateUser({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateUserData, id, data, token);

    toast('🦄 User updated successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    //yield put(getUserValue(token, 1, 10));
    if (response.status === 200) {
      history.push('/users');
    }
  } catch (error) {
    console.log(error.response);
    yield put(updateUserFail(error));
  }
}

function* UserSaga() {
  yield takeEvery(ADD_USER, onAddNewUser);
  yield takeEvery(GET_ALL_USER, fetchUsers);
  yield takeEvery(GET_ORG_USER, fetchOrgUser);
  yield takeEvery(GET_USER_DETAILS, fetchUserDetails);
  yield takeEvery(UPDATE_USER, onUpdateUser);
}

export default UserSaga;
