import {
  ADD_ACTIVATION,
  ADD_ACTIVATION_FAIL,
  ADD_ACTIVATION_SUCCESS,
  GET_ACTIVATION_DETAILS,
  GET_ACTIVATION_FAIL,
  GET_ACTIVATION_SUCCESS,
  GET_ALL_ACTIVATION,
  GET_ALL_ACTIVATION_FAIL,
  GET_ALL_ACTIVATION_SUCCESS,
  GET_QUESTION_DETAILS,
  GET_QUESTION_FAIL,
  GET_QUESTION_SUCCESS,
  STORE_ACTIVATION_DATA,
  STORE_QUESTION_DATA,
  UPDATE_ACTIVATION,
  UPDATE_ACTIVATION_FAIL,
  UPDATE_ACTIVATION_SUCCESS,
  UPDATE_QUESTION,
  UPDATE_QUESTION_FAIL,
  UPDATE_QUESTION_SUCCESS,
} from './actionTypes';

export const addActivation = (data, options) => ({
  type: ADD_ACTIVATION,
  payload: { data, options },
});

export const addActivationSuccess = (data) => ({
  type: ADD_ACTIVATION_SUCCESS,
  payload: data,
});

export const addActivationFail = (error) => ({
  type: ADD_ACTIVATION_FAIL,
  payload: error,
});
export const getActivations = (
  authtoken,
  orgId,
  status,
  list,
  currentPage
) => ({
  type: GET_ALL_ACTIVATION,
  payload: { authtoken, orgId, status, list, currentPage },
});

export const getActivationsSuccess = (data, authtoken) => ({
  type: GET_ALL_ACTIVATION_SUCCESS,
  payload: { data, authtoken },
});
export const getActivationsFail = (error) => ({
  type: GET_ALL_ACTIVATION_FAIL,
  payload: error,
});

export const getActivationDetails = (authtoken, id) => ({
  type: GET_ACTIVATION_DETAILS,
  payload: { authtoken, id },
});

export const getActivationDetailsSuccess = (data) => ({
  type: GET_ACTIVATION_SUCCESS,
  payload: { data },
});
export const getActivationDetailsFail = (error) => ({
  type: GET_ACTIVATION_FAIL,
  payload: error,
});

export const getQuestionsDetails = (authtoken, id) => ({
  type: GET_QUESTION_DETAILS,
  payload: { authtoken, id },
});

export const getQuestionsDetailsSuccess = (data) => ({
  type: GET_QUESTION_SUCCESS,
  payload: { data },
});
export const getQuestionsDetailsFail = (error) => ({
  type: GET_QUESTION_FAIL,
  payload: error,
});

//EDIT Activation
export const storeActivationData = (name, data) => ({
  type: STORE_ACTIVATION_DATA,
  payload: { name, data },
});

export const updateActivation = (id, data, token, history) => ({
  type: UPDATE_ACTIVATION,
  payload: { id, data, token, history },
});

export const updateActivationSuccess = (id, data) => ({
  type: UPDATE_ACTIVATION_SUCCESS,
  payload: { id, data },
});

export const updateActivationFail = (error) => ({
  type: UPDATE_ACTIVATION_FAIL,
  payload: error,
});

//EDIT qUESTION
export const storeQuestionData = (name, data) => ({
  type: STORE_QUESTION_DATA,
  payload: { name, data },
});

export const updateQuestion = (id, data, token, history) => ({
  type: UPDATE_QUESTION,
  payload: { id, data, token, history },
});

export const updateQuestionSuccess = (id, data) => ({
  type: UPDATE_QUESTION_SUCCESS,
  payload: { id, data },
});

export const updateQuestionFail = (error) => ({
  type: UPDATE_QUESTION_FAIL,
  payload: error,
});
