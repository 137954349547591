import { combineReducers } from "redux";
import ActivationReducer from "./Activation/reducers";
import AttendanceReducer from "./Attendance/reducers";
import DashboardReducer from "./Dashboard/reducer";
import ForgetPassword from "./ForgetPassword/reducer";
import Login from "./login/reducer";
import loginError from "./loginError/reducer";
import registration from "./registration/reducer";
import SurveyReducer from "./Survey/reducers";
import TargetReducer from "./Target/reducers";
import UserReducer from "./User/reducers";
const rootReducer = combineReducers({
  Login,
  registration,
  loginError,
  ForgetPassword,
  UserReducer,
  SurveyReducer,
  ActivationReducer,
  TargetReducer,
  DashboardReducer,
  AttendanceReducer,
});

export default rootReducer;
