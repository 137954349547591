import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Accordion, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Badge, Col, Input, Row, Spinner } from 'reactstrap';
import { IMG_API } from '../../helpers/api_helper';
import {
  getActivationDetails,
  getActivations,
  getUsers,
} from '../../store/actions';
import CardComponent from '../Layout/CardComponent';
import CustomTable from '../Layout/CustomTable';
import InnerLayer from '../Layout/InnerLayer';
import NoTableData from '../Layout/NoTableData';
const tableHead = [
  'Sl. No.',
  'Activation Name',
  'Activation ID',
  'Organization Name',
  'Status',
  'Action',
];
const statusList = [
  {
    name: 'Active',
    value: '1',
  },
  {
    name: 'Inactive',
    value: '0',
  },
];

const renderTooltipView = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    View Details
  </Tooltip>
);
const renderTooltipEdit = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Update
  </Tooltip>
);

const Question = ({ history }) => {
  let dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [handleList, setHandleList] = useState(10);
  const [questionLoading] = useState(false);
  const [status, setStatus] = useState(1);
  const [orgId, setOrgId] = useState('');

  const [viewDetail, setViewDetail] = useState(false);
  const { authtoken, activations, activationDetails, userId, userrole, users } =
    useSelector((state) => ({
      authtoken: state.Login.token,
      activations: state?.ActivationReducer?.activations,
      activationDetails: state?.ActivationReducer?.activationDetails,
      users: state?.UserReducer?.users,
      getActivationLoading: state?.ActivationReducer?.getActivationLoading,
      userrole: state.Login.userrole,
      userId: state.Login.warehouse,
    }));
  let totalPageNumber = Math.ceil(
    activations?.data?.data?.totalLength / handleList
  );
  useEffect(() => {
    if (userrole === 'ADMIN') {
      dispatch(
        getActivations(authtoken, orgId, status, handleList, currentPage)
      );
    } else if (userrole === 'ORG') {
      dispatch(
        getActivations(authtoken, userId, status, handleList, currentPage)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, orgId, status, handleList, currentPage]);

  useEffect(() => {
    dispatch(getUsers(authtoken, 'ORG', 'All', 0, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOrg = (value) => {
    if (value) {
      setOrgId(value._id);
    } else {
      setOrgId('');
    }
  };
  const handleStatus = (value) => {
    if (value) {
      setStatus(value.value);
    } else {
      setStatus('');
    }
  };
  const handleActivationView = (data) => {
    setViewDetail(true);
    dispatch(getActivationDetails(authtoken, data._id));
  };
  const handleListData = (e) => {
    setHandleList(e.target.value);
  };
  console.log('activations', activations);
  console.log('activationDetails', activationDetails);

  return (
    <React.Fragment>
      <InnerLayer
        title="Activation"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={'#'}
        mainTitle={'Activation'}
        subTitle={'Activation List'}
        onAddHandler={() => history.push('/add-question')}
        buttonText="Create Activation"
        isButton
      >
        <Row>
          <CardComponent className="user_table_card">
            <Row className="mb-3 table-header-padding">
              <div className="d-flex d-flex-blockCustom justify-content-end">
                <Col className="ms-2" md={2}>
                  <Select
                    className="mb-2 ms-2"
                    classNamePrefix="select2-selection"
                    placeholder="Select Status"
                    cacheOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    isClearable
                    // value={distributionHubData?.data?.Warehouse?.find((data)=>data?._id === wareHouse)}
                    options={statusList}
                    onChange={handleStatus}
                  />
                </Col>
                {userrole === 'ADMIN' ? (
                  <Col className="ms-2" md={2}>
                    <Select
                      className="mb-2 ms-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Organization"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      // value={distributionHubData?.data?.Warehouse?.find((data)=>data?._id === wareHouse)}
                      options={users?.data?.data?.users}
                      onChange={handleOrg}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                <Col className="ms-2" md={2}>
                  <Input type="select" onChange={handleListData}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Input>
                </Col>
              </div>
            </Row>
            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={[1, 2, 3]}
                pageNo={totalPageNumber}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
              >
                {questionLoading ? (
                  <tr style={{ width: '100%' }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: '100%' }}
                    >
                      <div>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </div>
                  </tr>
                ) : activations?.data?.data?.activations.length > 0 ? (
                  activations?.data?.data?.activations?.map((data, idx) => (
                    <tr>
                      <th scope="row" style={{ paddingLeft: '25px' }}>
                        {idx + 1}
                      </th>
                      <td>
                        <img
                          src={IMG_API + '/' + data.image}
                          alt="active"
                          style={{ width: '40px' }}
                          className="me-2"
                        />
                        {data?.name}
                      </td>
                      <td>{data?.activationId}</td>
                      <td>{data?.org?.name}</td>
                      <td>
                        {' '}
                        <Badge color="warning">
                          {data?.status ? 'Active' : 'Inactive'}
                        </Badge>
                      </td>

                      <td>
                        <div className="d-flex">
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipView}
                          >
                            <button
                              className="btn btn-info btn-sm me-2"
                              style={{ borderRadius: '10px' }}
                              onClick={() => handleActivationView(data)}
                            >
                              <i className="bx bx-show mt-1"></i>
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipEdit}
                          >
                            <button
                              className="btn btn-outline-info btn-sm"
                              style={{ borderRadius: '10px' }}
                              onClick={() => {
                                history.push(`/edit-question/${data?._id}`);
                              }}
                            >
                              <i className="bx bx-edit mt-1"></i>
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan="6"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: '300px', width: `100%` }}
                  >
                    <span>No data Available</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
              <Modal
                size="lg"
                show={viewDetail}
                onHide={() => setViewDetail(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header
                  closeButton
                  style={{ borderBottom: 'none' }}
                ></Modal.Header>
                <Modal.Body style={{ padding: '3px 25px' }}>
                  <h4 className="mb-4" style={{ color: '#058DE0' }}>
                    Activation Details
                  </h4>
                  <div className="outter-wrap p-4">
                    <div className="inner-text-area">
                      <span className="font-size-12">Activation Name</span>
                      <h6 className="font-size-14 mt-2 ms-2">
                        <img
                          src={
                            IMG_API + '/' + activationDetails?.activation?.image
                          }
                          alt="active_image"
                          style={{ width: '40px' }}
                          className="me-2"
                        />
                        {activationDetails?.activation?.name}
                      </h6>
                      <div className="text_border_bottom"></div>
                    </div>
                    <div className="inner-text-area mt-4">
                      <span className="font-size-12">Activation ID</span>
                      <h6 className="font-size-14 mt-2 ms-2">
                        {activationDetails?.activation?.activationId}
                      </h6>
                      <div className="text_border_bottom"></div>
                    </div>

                    <div className="inner-text-area mt-4">
                      <span className="font-size-12">Organization Name</span>
                      <h6 className="font-size-14 mt-2 ms-2">
                        {activationDetails?.activation?.org?.name}
                      </h6>
                      <div className="text_border_bottom"></div>
                    </div>

                    <Accordion
                      defaultActiveKey="0"
                      flush
                      className="question_feature"
                    >
                      {activationDetails?.questions?.map((question, idx) => (
                        <Accordion.Item eventKey={idx} flush>
                          <Accordion.Header>
                            <h6>
                              <span
                                style={{
                                  fontWeight: 'normal !important',
                                  color: '#7c7978',
                                }}
                              >
                                Question {idx + 1} : <span></span>
                              </span>
                              {question?.name}
                            </h6>
                          </Accordion.Header>
                          <Accordion.Body>
                            {(question?.options || []).map((data, key) => {
                              return (
                                <>
                                  <span className="font-size-12">
                                    Option {data.serial}
                                  </span>
                                  <h6 className="font-size-14 mt-2 ms-2">
                                    <img
                                      src={IMG_API + '/' + data.image}
                                      alt="active"
                                      style={{ width: '30px' }}
                                      className="me-2"
                                    />
                                    {data.name}
                                  </h6>
                                  <div className="accordion_border_bottom"></div>
                                </>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                    <div className="accordion_border_bottom"></div>
                  </div>
                </Modal.Body>
              </Modal>
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  );
};

Question.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(Question);
