import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOrg, getOrgBp } from "../../../store/actions";
// import { Card, CardBody } from "reactstrap";
const Survey = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orgData, orgBpData, authtoken, role } = useSelector((state) => ({
    authtoken: state.Login.token,
    orgData: state.DashboardReducer.orgData,
    orgBpData: state.DashboardReducer.orgBpData,
    role: state.Login.userrole,
  }));
  useEffect(() => {
    role == "ADMIN" ? (
      dispatch(getOrg(authtoken))
    ) : role == "ORG" ? (
      dispatch(dispatch(getOrgBp(authtoken)))
    ) : (
      <></>
    );
  }, []);

  if (role == "ADMIN") {
    var org1 = orgData?.data?.surveyData[0]?.organization[0]?.name;
    var org2 = orgData?.data?.surveyData[1]?.organization[0]?.name;
    var org3 = orgData?.data?.surveyData[2]?.organization[0]?.name;

    var orgUnit1 = orgData?.data?.surveyData[0]?.count || 0;
    var orgUnit2 = orgData?.data?.surveyData[1]?.count || 0;
    var orgUnit3 = orgData?.data?.surveyData[2]?.count || 0;
  } else {
    var org1 = orgBpData?.data?.surveyData[0]?.bp[0]?.name;
    var org2 = orgBpData?.data?.surveyData[1]?.bp[0]?.name;
    var org3 = orgBpData?.data?.surveyData[2]?.bp[0]?.name;

    var orgUnit1 = orgBpData?.data?.surveyData[0]?.count || 0;
    var orgUnit2 = orgBpData?.data?.surveyData[1]?.count || 0;
    var orgUnit3 = orgBpData?.data?.surveyData[2]?.count || 0;
  }

  var series = [orgUnit1, orgUnit2, orgUnit3];
  const options = {
    labels: [org1 || "", org2 || "", org3 || ""],
    colors: ["#F46A6A", "#556EE6", "#198754"],
    legend: { show: 1, position: "bottom" },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          background: "transparent",
          labels: {
            // show: true,
            name: {
              show: false,
              fontSize: "16px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },

            // total: {
            //   show: true,
            //   showAlways: true,
            // },
          },
        },
      },
    },
  };
  return (
    <>
      <Card className="brandanalytic" style={{ height: "434px" }}>
        <Card.Body>
          <div className="chart-title-top-content">
            <h6 className="card-title">
              {role == "ADMIN" ? (
                "Top Surveyor (Organization)"
              ) : role == "ORG" ? (
                "Top Surveyor (BP)"
              ) : (
                <></>
              )}
            </h6>
          </div>

          <div>
            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={series || []}
                  type="donut"
                  height={350}
                  className="apex-charts"
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default Survey;
