import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { Row } from "reactstrap";
import { IMG_API } from "../../helpers/api_helper";
import { getSurveyDetails } from "../../store/actions";
import InnerLayer from "../Layout/InnerLayer";

const SurveyDetail = ({ history }) => {
  let dispatch = useDispatch();
  const { id } = useParams();

  const { authtoken, surveyDetails } = useSelector((state) => ({
    authtoken: state.Login.token,
    surveyDetails: state.SurveyReducer.surveyDetails,
  }));
  useEffect(() => {
    dispatch(getSurveyDetails(authtoken, id));
  }, []);
  console.log("surveyDetails", surveyDetails);
  return (
    <React.Fragment>
      <InnerLayer
        title="Survey"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Survey"}
        subTitle={"Survey Detail"}
      >
        <Row>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="text-center mt-4">General Info</h4>
                <div className="outter-wrap p-4">
                  <div className="inner-text-area">
                    <h6 className="font-size-12">Name</h6>
                    <span className="font-size-14">
                      {
                        surveyDetails?.data?.data?.Survey?.infocollectID
                          ?.fullName
                      }
                    </span>
                    <div className="text_border_bottom"></div>
                  </div>
                  <div className="inner-text-area mt-4">
                    <h6 className="font-size-12">Address</h6>
                    <span className="font-size-14">
                      {" "}
                      {
                        surveyDetails?.data?.data?.Survey?.infocollectID
                          ?.address
                      }
                    </span>
                    <div className="text_border_bottom"></div>
                  </div>
                  <div className="inner-text-area mt-4">
                    <h6 className="font-size-12">Date of Birth</h6>
                    <span className="font-size-14">
                      {" "}
                      {surveyDetails?.data?.data?.Survey?.infocollectID?.dob}
                    </span>
                    <div className="text_border_bottom"></div>
                  </div>

                  <div className="inner-text-area mt-4">
                    <h6 className="font-size-12">Phone Number</h6>
                    <span className="font-size-14">
                      {" "}
                      {
                        surveyDetails?.data?.data?.Survey?.infocollectID
                          ?.mobileNumber
                      }
                    </span>
                    <div className="text_border_bottom"></div>
                  </div>
                  <div className="inner-text-area mt-4">
                    <h6 className="font-size-12">Institution Name</h6>
                    <span className="font-size-14">
                      {" "}
                      {
                        surveyDetails?.data?.data?.Survey?.infocollectID
                          ?.instituteName
                      }
                    </span>
                    <div className="text_border_bottom"></div>
                  </div>
                  <div className="inner-text-area mt-4">
                    <h6 className="font-size-12">Info Taken date</h6>
                    <span className="font-size-14">
                      {
                        surveyDetails?.data?.data?.Survey?.infocollectID
                          ?.infotakenDate
                      }
                    </span>
                    <div className="text_border_bottom"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="text-center mt-4">Survey</h4>
                <div className="outter-wrap p-4">
                  <div className="d-flex align-items-center">
                    <h6 className="font-size-12 me-2 mt-2">Org Name : </h6>
                    <span className="font-size-14">
                      {" "}
                      {surveyDetails?.data?.data?.Survey?.org?.name}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <h6 className="font-size-12 me-2 mt-2">BP Name : </h6>
                    <span className="font-size-14">
                      {" "}
                      {surveyDetails?.data?.data?.Survey?.user?.name}
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-5">
                    <h6 className="font-size-12 me-2 mt-2">
                      Activation Name :{" "}
                    </h6>
                    <span className="font-size-14">
                      {" "}
                      {surveyDetails?.data?.data?.Survey?.activation?.name}
                    </span>
                  </div>

                  {(surveyDetails?.data?.data?.Survey?.questions || []).map(
                    (data, key) => {
                      return (
                        <>
                          <div className="inner-text-area">
                            <h6 className="font-size-12">{data.question}</h6>
                            <img
                              src={IMG_API + "/" + data.image}
                              alt="active"
                              style={{ width: "40px" }}
                              className="me-2"
                            />
                            <span className="font-size-14">
                              {data.givenanswer}
                            </span>
                            <div className="text_border_bottom mb-4"></div>
                          </div>
                        </>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </InnerLayer>
    </React.Fragment>
  );
};

SurveyDetail.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(SurveyDetail);
