import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSurvey } from "../../../store/actions";
// import { Card, CardBody } from "reactstrap";
const Organization = () => {
  const dispatch = useDispatch();
  const { authtoken, SurveyData } = useSelector((state) => ({
    authtoken: state.Login.token,
    SurveyData: state.DashboardReducer.SurveyData,
  }));

  useEffect(() => {
    dispatch(getSurvey(authtoken));
  }, []);
  console.log("SurveyData", SurveyData?.data?.surveyData);
  let monthData = [];
  let quantityData = [];
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  SurveyData?.data?.surveyData?.map((data, i) => {
    quantityData?.push(data?.count);
    monthData?.push(data?._id?.date + " " + months[data?._id?.month - 1]);
  });
  console.log("monthData", monthData);
  const options = {
    series: [
      {
        name: "Quantity",
        data: quantityData || [],
      },
    ],
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
    },
    // colors: [
    //   "#438EFE",
    //   "#438EFE",
    //   "#438EFE",
    //   "#438EFE",
    //   "#438EFE",
    //   "#438EFE",
    //   "#438EFE",
    // ],
    colors: ["#438EFE"],
    plotOptions: {
      bar: {
        columnWidth: "15%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: monthData || [],
      labels: {
        style: {
          // colors: colors,
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  };
  return (
    <>
      <Card className="brandanalytic">
        <Card.Body>
          <div className="chart-title-top-content">
            <h6 className="card-title">Survey</h6>
          </div>

          <div>
            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={options.series}
                  type="bar"
                  height={350}
                  className="apex-charts"
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default Organization;
