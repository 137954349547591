import {
  ADD_USER,
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  EMPTY_EDIT_USER_INFO,
  GET_ALL_USER,
  GET_ALL_USER_FAIL,
  GET_ALL_USER_SUCCESS,
  GET_ORG_USER,
  GET_ORG_USER_FAIL,
  GET_ORG_USER_SUCCESS,
  GET_SUPERVISOR,
  GET_SUPERVISOR_FAIL,
  GET_SUPERVISOR_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_FAIL,
  GET_USER_DETAILS_SUCCESS,
  STORE_USER_DATA,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from "./actionTypes";

export const addUser = (data, history, authtoken) => ({
  type: ADD_USER,
  payload: { data, history, authtoken },
});

export const addUserSuccess = (data) => ({
  type: ADD_USER_SUCCESS,
  payload: data,
});

export const addUserFail = (error) => ({
  type: ADD_USER_FAIL,
  payload: error,
});
export const getUsers = (authtoken, role, orgId, list, currentPage) => ({
  type: GET_ALL_USER,
  payload: { authtoken, role, orgId, list, currentPage },
});

export const getUsersSuccess = (data, authtoken) => ({
  type: GET_ALL_USER_SUCCESS,
  payload: { data, authtoken },
});

export const getOrgUser = (authtoken, role, orgId, list, currentPage) => ({
  type: GET_ORG_USER,
  payload: { authtoken, role, orgId, list, currentPage },
});
export const getOrgUserFail = (error) => ({
  type: GET_ORG_USER_FAIL,
  payload: error,
});

export const getOrgUserSuccess = (data, authtoken) => ({
  type: GET_ORG_USER_SUCCESS,
  payload: { data, authtoken },
});

export const getUsersFail = (error) => ({
  type: GET_ALL_USER_FAIL,
  payload: error,
});
export const getSuperVisor = (authtoken, role, list, currentPage) => ({
  type: GET_SUPERVISOR,
  payload: { authtoken, role, list, currentPage },
});

export const getSuperVisorSuccess = (data, authtoken) => ({
  type: GET_SUPERVISOR_SUCCESS,
  payload: { data, authtoken },
});

export const getSuperVisorFail = (error) => ({
  type: GET_SUPERVISOR_FAIL,
  payload: error,
});

export const getUserDetails = (authtoken, id) => ({
  type: GET_USER_DETAILS,
  payload: { authtoken, id },
});

export const getUserDetailsSuccess = (data) => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: { data },
});
export const getUserDetailsFail = (error) => ({
  type: GET_USER_DETAILS_FAIL,
  payload: error,
});

//EDIT USER
export const storeUserData = (name, data) => ({
  type: STORE_USER_DATA,
  payload: { name, data },
});

export const updateUser = (id, data, token, history) => ({
  type: UPDATE_USER,
  payload: { id, data, token, history },
});

export const updateUserSuccess = (id, data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { id, data },
});

export const updateUserFail = (error) => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});

export const emptyEditUser = () => ({
  type: EMPTY_EDIT_USER_INFO,
});
