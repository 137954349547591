import { toast } from "react-toastify";
import { call, delay, put, takeEvery } from "redux-saga/effects";
import {
  axiosGetTarget,
  getTargetAchievementData,
  postTargetData,
  updateTargetData,
} from "../../helpers/api_helper";
import {
  addTargetFail,
  getTargetAchievementFail,
  getTargetAchievementSuccess,
  getTargetValueSuccess,
  modifyTargetDataSuccess,
  updateTargetFail,
} from "./action";
// Calender Redux States
import {
  ADD_NEW_TARGET,
  GET_TARGET_ACHIEVEMENT,
  GET_TARGET_VALUE,
  MODIFY_TARGET_DATA,
  UPDATE_TARGET,
} from "./actionTypes";

//target
const asyncGetTarget = async (authtoken, userId) => {
  try {
    const response = await axiosGetTarget(authtoken, userId);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetTarget({ payload: { authtoken, userId } }) {
  try {
    const response = yield call(asyncGetTarget, authtoken, userId);
    yield put(getTargetValueSuccess(response.target));
  } catch (error) {}
}

//async
const asyncPostTargetData = async (data, authtoken) => {
  try {
    const response = await postTargetData(data, authtoken);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

function* onAddTarget({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostTargetData, data, authtoken);
    if (response.status == "success") {
      toast("🦄 Target added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("target-set");
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    yield put(addTargetFail(error.message));
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

function* onUpdateTarget({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(updateTargetData, data, authtoken);
    toast("Target updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (response.status === 200) {
      history.push("/target-set");
    }
  } catch (error) {
    console.log(error.response);
    yield put(updateTargetFail(error));
  }
}

function* fetchTargetAchievement({ payload: { authtoken, userId, year } }) {
  try {
    delay(500);

    const response = yield call(
      getTargetAchievementData,
      authtoken,
      userId,
      year
    );

    yield put(getTargetAchievementSuccess(response));
  } catch (error) {
    yield put(getTargetAchievementFail(error));
  }
}

function* workerModifyTargetData({ payload: { index, target, month, data } }) {
  data[index] = {
    ...data[index],
    date: month,
    actualTarget: target,
  };
  yield put(modifyTargetDataSuccess(data));
}

function* TargetSaga() {
  yield takeEvery(GET_TARGET_VALUE, workerGetTarget);
  yield takeEvery(ADD_NEW_TARGET, onAddTarget);
  yield takeEvery(UPDATE_TARGET, onUpdateTarget);
  yield takeEvery(GET_TARGET_ACHIEVEMENT, fetchTargetAchievement);
  yield takeEvery(MODIFY_TARGET_DATA, workerModifyTargetData);
}

export default TargetSaga;
