import {
  GET_ORG,
  GET_ORG_BP,
  GET_ORG_BP_FAIL,
  GET_ORG_BP_SUCCESS,
  GET_ORG_FAIL,
  GET_ORG_SUCCESS,
  GET_SURVEY,
  GET_SURVEY_FAIL,
  GET_SURVEY_SUCCESS,
  GET_TOP_BANNER,
  GET_TOP_BANNER_FAIL,
  GET_TOP_BANNER_SUCCESS,
} from "./actionTypes";

export const getTopBanner = (authtoken, role) => ({
  type: GET_TOP_BANNER,
  payload: { authtoken, role },
});

export const getTopBannerSuccess = (data) => ({
  type: GET_TOP_BANNER_SUCCESS,
  payload: { data },
});
export const getTopBannerFail = (error) => ({
  type: GET_TOP_BANNER_FAIL,
  payload: error,
});
export const getSurvey = (authtoken) => ({
  type: GET_SURVEY,
  payload: { authtoken },
});

export const getSurveySuccess = (data) => ({
  type: GET_SURVEY_SUCCESS,
  payload: { data },
});
export const getSurveyFail = (error) => ({
  type: GET_SURVEY_FAIL,
  payload: error,
});

export const getOrg = (authtoken) => ({
  type: GET_ORG,
  payload: { authtoken },
});

export const getOrgSuccess = (data) => ({
  type: GET_ORG_SUCCESS,
  payload: { data },
});

export const getOrgFail = (error) => ({
  type: GET_ORG_FAIL,
  payload: error,
});

export const getOrgBp = (authtoken) => ({
  type: GET_ORG_BP,
  payload: { authtoken },
});

export const getOrgBpSuccess = (data) => ({
  type: GET_ORG_BP_SUCCESS,
  payload: { data },
});

export const getOrgBpFail = (error) => ({
  type: GET_ORG_BP_FAIL,
  payload: error,
});
