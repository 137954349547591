import moment from "moment";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Col, Input, Row } from "reactstrap";
import * as XLSX from "xlsx";
import { get, IMG_API } from "../../helpers/api_helper";
import { getOrgUser, getUsers } from "../../store/actions";
import { getAttendance } from "../../store/Attendance/actions";
import CardComponent from "../Layout/CardComponent";
import CustomTable from "../Layout/CustomTable";
import InnerLayer from "../Layout/InnerLayer";
import NoTableData from "../Layout/NoTableData";
const tableHead = [
  "Sl. No.",
  "Name",
  "Employee ID",
  "Role",
  "In Time",
  "Image",
];

const Attendance = () => {
  const dispatch = useDispatch();
  const [role, setRole] = useState("SO");
  const [loading, setLoading] = useState(false);
  const [orgId, setOrgId] = useState("All");
  const [orgIdFilter, setOrgIdFilter] = useState("All");
  const [userBpFilterId, setUserBpFilterId] = useState("All");
  const [userBpId, setUserBpId] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [handleList, setHandleList] = useState(10);
  const [userRoleBp, setUserRoleBp] = useState("BP");
  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const {
    authtoken,
    attendance,
    attendanceLoading,
    orgUser,
    userrole,
    users,
    userId,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    userrole: state.Login.userrole,
    attendance: state?.AttendanceReducer?.attendance,
    attendanceLoading: state?.AttendanceReducer?.attendanceLoading,
    orgUser: state?.UserReducer?.orgUser,
    users: state?.UserReducer?.users,
    userId: state.Login.warehouse,
  }));
  useEffect(() => {
    let obj = {};
    obj.fromDate = fromDate;
    obj.toDate = currentDate;
    userrole == "ADMIN" ? (
      dispatch(
        getAttendance(
          authtoken,
          orgId,
          userBpId,
          fromDate,
          handleList,
          currentPage
        )
      )
    ) : userrole == "ORG" ? (
      dispatch(
        getAttendance(
          authtoken,
          userId,
          userBpId,
          fromDate,
          handleList,
          currentPage
        )
      )
    ) : (
      <></>
    );
  }, [fromDate, orgId, userBpId, handleList, currentPage, userId]);

  useEffect(() => {
    dispatch(
      getUsers(authtoken, userRoleBp, userBpFilterId, handleList, currentPage)
    );
  }, [userRoleBp, userBpFilterId, handleList, currentPage]);
  useEffect(() => {
    dispatch(
      getOrgUser(authtoken, "ORG", orgIdFilter, handleList, currentPage)
    );
  }, [orgIdFilter, handleList, currentPage]);
  console.log("orgUser", orgUser);
  const handleChangeDate = (e) => {
    const value = e.target.value;

    setCurrentDate(value);
  };
  const handleFromDate = (e) => {
    const value = e.target.value;

    setFromDate(value);
  };

  const handleChangeRole = (e) => {
    setRole(e.target.value);
  };

  const handleExcelDownload = async () => {
    let obj = {};
    obj.fromDate = fromDate;
    obj.toDate = currentDate;
    setLoading(true);
    let attendanceData = [];
    await get(
      `/attendance/datewiseattendance?orgId=${orgId}&userId=${userBpId}&date=${fromDate}&limit=${handleList}&pageNo=${currentPage}`,
      {
        headers: { Authorization: `Bearer ${authtoken}` },
      }
    )
      .then((response) => {
        response?.todaygivenattandance?.forEach((attendance, index) => {
          let data = {};
          data.Name = attendance?.userId?.name;
          data.EmployeeId = attendance?.userId?.employeeId;
          data.Role = attendance?.userId?.role;
          data.InTime = attendance?.intime;
          data.status = attendance?.isLate;
          data.date = attendance?.dateid;
          data.Phone = attendance?.userId?.phoneNumber;
          attendanceData.push(data);
        });
      })
      .catch((error) => {
        console.log("kkkk");
      });
    downloadxls(attendanceData);
  };
  const downloadxls = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Attendance.xlsx");
    setLoading(false);
  };
  const handleBpFilter = (value) => {
    if (value) {
      setUserBpId(value._id);
    } else {
      setUserBpId("All");
    }
  };
  const handleOrgFilter = (value) => {
    if (value) {
      setOrgId(value._id);
      setUserBpFilterId(value._id);
    } else {
      setOrgId("All");
      setUserBpFilterId("All");
    }
  };
  console.log("setUserBpId", userBpId);
  console.log("setOrgId", orgId);

  return (
    <React.Fragment>
      <InnerLayer
        title="Attendance"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Attendance"}
        subTitle={"Attendance List"}
      >
        <Row>
          <CardComponent className="user_table_card">
            <Row className="mb-3 table-header-padding">
              <div className="d-flex d-flex-blockCustom justify-content-end">
                <Col md={2} className="">
                  <button
                    className="btn btn-success"
                    onClick={handleExcelDownload}
                    disabled={loading}
                  >
                    {loading ? "Downloading..." : "Download Excel"}
                  </button>
                </Col>
                {userrole == "ADMIN" ? (
                  <Col className="me-3" md={2}>
                    <Select
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Organization"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={orgUser?.data?.data?.users}
                      onChange={handleOrgFilter}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                {userrole == "ADMIN" ? (
                  <Col md={2} className="me-3">
                    <Select
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select BP"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={users?.data?.data?.users}
                      onChange={handleBpFilter}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                <Col md={2} className="custom-bottom-margin">
                  <div className="d-flex d-flex-blockCustom">
                    <Input
                      type="date"
                      value={fromDate}
                      onChange={handleFromDate}
                    />{" "}
                    {/* <div className="ms-3 me-3 mt-2 custom-center">To</div> */}
                  </div>
                </Col>
                {/* <Col md={2} className="custom-bottom-margin">
                  <Input
                    type="date"
                    value={currentDate}
                    // value={moment(currentDate).format("YYYY-MM-DD")}
                    onChange={handleChangeDate}
                  />
                </Col> */}
              </div>
            </Row>
            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={[1, 2, 3]}
                // pageNo={totalPageNumber}
                tableHead={tableHead}
                // setCurrentPage={setCurrentPage}
                // isPagination
              >
                {attendanceLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </div>
                  </tr>
                ) : attendance?.data?.data?.todaygivenattandance?.length > 0 ? (
                  attendance?.data?.data?.todaygivenattandance?.map(
                    (data, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>{data?.userId?.name}</td>
                        <td>{data?.userId?.employeeId}</td>
                        <td>{data?.userId?.role}</td>
                        <td>{data?.intime}</td>
                        <td>
                          {" "}
                          <img
                            src={IMG_API + "/" + data.photo}
                            alt="user_photo"
                            style={{ width: "40px", height: "40px" }}
                            className="me-2"
                          />
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <NoTableData
                    colSpan="6"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>No data Available</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  );
};

export default Attendance;
