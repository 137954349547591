export const ADD_ACTIVATION = "ADD_ACTIVATION";
export const ADD_ACTIVATION_SUCCESS = "ADD_ACTIVATION_SUCCESS";
export const ADD_ACTIVATION_FAIL = "ADD_ACTIVATION_FAIL";

export const GET_ALL_ACTIVATION = "GET_ALL_ACTIVATION";
export const GET_ALL_ACTIVATION_SUCCESS = "GET_ALL_ACTIVATION_SUCCESS";
export const GET_ALL_ACTIVATION_FAIL = "GET_ALL_ACTIVATION_FAIL";

export const GET_QUESTION_DETAILS = "GET_QUESTION_DETAILS";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAIL = "GET_QUESTION_FAIL";

export const GET_ACTIVATION_DETAILS = "GET_ACTIVATION_DETAILS";
export const GET_ACTIVATION_SUCCESS = "GET_ACTIVATION_SUCCESS";
export const GET_ACTIVATION_FAIL = "GET_ACTIVATION_FAIL";

//EDIT Activation
export const UPDATE_ACTIVATION = "UPDATE_ACTIVATION";
export const UPDATE_ACTIVATION_SUCCESS = "UPDATE_ACTIVATION_SUCCESS";
export const UPDATE_ACTIVATION_FAIL = "UPDATE_ACTIVATION_FAIL";

export const STORE_ACTIVATION_DATA = "STORE_ACTIVATION_DATA";

//EDIT Question
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_FAIL = "UPDATE_QUESTION_FAIL";

export const STORE_QUESTION_DATA = "STORE_QUESTION_DATA";
