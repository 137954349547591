import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Badge, Col, Input, Row, Spinner } from "reactstrap";
import { getOrgUser, getSurveys, getUsers } from "../../store/actions";
import CardComponent from "../Layout/CardComponent";
import CustomTable from "../Layout/CustomTable";
import InnerLayer from "../Layout/InnerLayer";
import NoTableData from "../Layout/NoTableData";
const tableHead = [
  "Sl. No.",
  "Org Name",
  "BP Name",
  "Date",
  "Status",
  "Action",
];

const renderTooltipView = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    View Details
  </Tooltip>
);
const HUbType = [
  {
    name: "Central WareHouse",
    _id: 1,
    value: "Central",
  },
  {
    name: "Distribution Hub",
    _id: 2,
    value: "Hub",
  },
];

const Survey = ({ history }) => {
  let dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [handleList, setHandleList] = useState(10);
  const [productStockLoading, setProductStockLoading] = useState(false);
  const statusList = [
    {
      name: "Verifed",
      value: 1,
    },
    {
      name: "Connection",
      value: 0,
    },
  ];

  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [orgId, setOrgId] = useState("");
  const [orgUserId, setOrgUserId] = useState("All");
  const [bpUserId, setBpUserId] = useState("");
  const [status, setStatus] = useState("");

  const { authtoken, surveys, users, orgUser, userrole, userId } = useSelector(
    (state) => ({
      authtoken: state.Login.token,
      surveys: state?.SurveyReducer?.surveys,
      users: state?.UserReducer?.users,
      orgUser: state?.UserReducer?.orgUser,
      userrole: state.Login.userrole,
      userId: state.Login.warehouse,
    })
  );
  let totalPageNumber = Math.ceil(
    surveys?.data?.data?.totalLength / handleList
  );
  useEffect(() => {
    dispatch(getUsers(authtoken, "ORG", orgUserId, 0, 0));
  }, []);
  useEffect(() => {
    dispatch(getOrgUser(authtoken, "BP", orgUserId, 0, 0));
  }, []);

  useEffect(() => {
    let obj = {};
    obj.fromDate = fromDate;
    obj.currentDate = currentDate;
    obj.orgId = orgId;
    obj.bpUserId = bpUserId;
    obj.status = status;
    userrole == "ADMIN" ? (
      dispatch(
        dispatch(
          getSurveys(
            authtoken,
            orgId,
            bpUserId,
            status,
            fromDate,
            currentDate,
            handleList,
            currentPage
          )
        )
      )
    ) : userrole == "ORG" ? (
      dispatch(
        dispatch(
          getSurveys(
            authtoken,
            userId,
            bpUserId,
            status,
            fromDate,
            currentDate,
            handleList,
            currentPage
          )
        )
      )
    ) : (
      <></>
    );
  }, [
    userId,
    fromDate,
    currentDate,
    orgId,
    bpUserId,
    status,
    currentPage,
    handleList,
  ]);

  const handleChangeDate = (e) => {
    const value = e.target.value;
    setCurrentDate(value);
  };
  const handleFromDate = (e) => {
    const value = e.target.value;
    setFromDate(value);
  };
  const handleOrg = (value) => {
    if (value) {
      setOrgId(value._id);
    } else {
      setOrgId("");
    }
  };
  const handleBP = (value) => {
    if (value) {
      setBpUserId(value._id);
    } else {
      setBpUserId("");
    }
  };
  const handleStatus = (value) => {
    if (value) {
      setStatus(value.value);
    } else {
      setStatus("");
    }
  };
  const handleListData = (e) => {
    setHandleList(e.target.value);
  };

  return (
    <React.Fragment>
      <InnerLayer
        title="Survey"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Survey"}
        subTitle={"Survey List"}
      >
        <Row>
          <CardComponent className="user_table_card">
            <Row className="mb-3 table-header-padding">
              <div className="d-flex d-flex-blockCustom justify-content-end">
                <Col md={2} className="custom-bottom-margin">
                  <div className="d-flex d-flex-blockCustom">
                    <Input
                      name="fromDate"
                      type="date"
                      className=""
                      value={fromDate}
                      onChange={handleFromDate}
                    />{" "}
                    <div className="ms-3 me-3 mt-2 custom-center">To</div>
                  </div>
                </Col>
                <Col md={2} className="custom-bottom-margin">
                  <Input
                    type="date"
                    value={currentDate}
                    // value={moment(currentDate).format("YYYY-MM-DD")}
                    onChange={handleChangeDate}
                  />
                </Col>
                <Col md={2} className="custom-bottom-margin">
                  <Select
                    className="mb-2 ms-2"
                    classNamePrefix="select2-selection"
                    placeholder="Select Status"
                    cacheOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    isClearable
                    // value={distributionHubData?.data?.Warehouse?.find((data)=>data?._id === wareHouse)}
                    options={statusList}
                    onChange={handleStatus}
                  />
                </Col>

                <Col md={2} className="custom-bottom-margin">
                  <Select
                    name="Warehouse"
                    className="mb-2 ms-2"
                    classNamePrefix="select2-selection"
                    placeholder="Select BP"
                    cacheOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    isClearable
                    // value={distributionHubData?.data?.Warehouse?.find((data)=>data?._id === wareHouse)}
                    options={orgUser?.data?.data?.users}
                    onChange={handleBP}
                  />
                </Col>

                {userrole == "ADMIN" ? (
                  <Col md={2} className="custom-bottom-margin">
                    <Select
                      className="mb-2 ms-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Organization"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={users?.data?.data?.users}
                      onChange={handleOrg}
                    />
                  </Col>
                ) : (
                  <></>
                )}

                <Col className="ms-2" md={2}>
                  <Input type="select" onChange={handleListData}>
                    {/* <option defaultValue>Select...</option> */}
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Input>
                </Col>
              </div>
            </Row>
            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={[1, 2, 3]}
                pageNo={totalPageNumber}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
              >
                {productStockLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </div>
                  </tr>
                ) : surveys?.data?.data?.surveys?.length > 0 ? (
                  surveys?.data?.data?.surveys?.map((data, idx) => (
                    <tr>
                      <th scope="row" style={{ paddingLeft: "25px" }}>
                        {idx + 1}
                      </th>
                      <td>{data.org.name}</td>
                      <td>{data.user.name}</td>
                      <td>{data.date}</td>
                      <td>
                        {" "}
                        <Badge color="warning">
                          {data.infocollect == 1 ? "Verified" : "Connection"}
                        </Badge>
                      </td>

                      <td>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipView}
                        >
                          <button
                            className="btn btn-outline-info btn-sm"
                            style={{ borderRadius: "10px" }}
                            onClick={() => {
                              history.push(`/survey-detail/${data?._id}`);
                            }}
                          >
                            <i className="bx bx-show mt-1"></i>
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan="6"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>No data Available</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  );
};

Survey.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(Survey);
