import { call, put, takeEvery } from "redux-saga/effects";
import { getData } from "../../helpers/api_helper";
import {
  getOrgBpFail,
  getOrgBpSuccess,
  getOrgFail,
  getOrgSuccess,
  getSurveyFail,
  getSurveySuccess,
  getTopBannerFail,
  getTopBannerSuccess,
} from "./action.js";
import { GET_ORG, GET_ORG_BP, GET_SURVEY, GET_TOP_BANNER } from "./actionTypes";

function* fetchTopBanner({ payload: { authtoken, role } }) {
  try {
    if (role == "ADMIN") {
      const url = `dashboard/top-banner`;
      const response = yield call(getData, url, authtoken);
      yield put(getTopBannerSuccess(response));
    } else if (role == "ORG") {
      const url = `dashboard/org-top-banner`;
      const response = yield call(getData, url, authtoken);
      yield put(getTopBannerSuccess(response));
    }
  } catch (error) {
    yield put(getTopBannerFail(error));
  }
}
function* fetchSurvey({ payload: { authtoken } }) {
  try {
    const url = `dashboard/survey-datewise`;
    const response = yield call(getData, url, authtoken);

    yield put(getSurveySuccess(response));
  } catch (error) {
    yield put(getSurveyFail(error));
  }
}
function* fetchOrg({ payload: { authtoken } }) {
  try {
    const url = `dashboard/top-organization`;
    const response = yield call(getData, url, authtoken);
    yield put(getOrgSuccess(response));
  } catch (error) {
    yield put(getOrgFail(error));
  }
}
function* fetchOrgBp({ payload: { authtoken } }) {
  try {
    const url = `dashboard/top-organization-bp`;
    const response = yield call(getData, url, authtoken);
    yield put(getOrgBpSuccess(response));
  } catch (error) {
    yield put(getOrgBpFail(error));
  }
}

function* DashboardSaga() {
  yield takeEvery(GET_TOP_BANNER, fetchTopBanner);
  yield takeEvery(GET_SURVEY, fetchSurvey);
  yield takeEvery(GET_ORG, fetchOrg);
  yield takeEvery(GET_ORG_BP, fetchOrgBp);
}

export default DashboardSaga;
