import * as Yup from 'yup';

const createQuestionSchema = Yup.object({
  name: Yup.string().required('This value is Required'),
  org: Yup.string().required('This value is Required'),
  dummyImageId: Yup.string().required('This value is Required'),
  //   price: Yup.string().when('priceType', {
  //     is: 'paid',
  //     then: Yup.string().required('This value is Required'),
  //   }),
  options: Yup.array().of(
    Yup.object().shape({
      serial: Yup.string().required('This value is Required'),
      name: Yup.string().required('This value is Required'),
      image: Yup.string().required('This value is Required'),
    })
  ),
  activationId: Yup.string().required('This value is Required'),
  questionType: Yup.string().required('This value is Required'),
  qName: Yup.string().required('This value is Required'),
  //   expire: Yup.string().required('This value is Required'),
  //   content: Yup.string().required('This value is Required'),
});

const EditQuestionSchema = Yup.object({
  name: Yup.string().required('This value is Required'),
  org: Yup.string().required('This value is Required'),
  dummyImageId: Yup.string().required('This value is Required'),
  activationId: Yup.string().required('This value is Required'),
});

const createQuestionSchema2 = Yup.object({
  name: Yup.string().required('This value is Required'),
  options: Yup.array().of(
    Yup.object().shape({
      serial: Yup.string().required('This value is Required'),
      name: Yup.string().required('This value is Required'),
      image: Yup.string().required('This value is Required'),
    })
  ),
  dependingQuetion: Yup.string().required('This value is Required'),
  dependingOption: Yup.string().required('This value is Required'),
  questionType: Yup.string().required('This value is Required'),
});

const EditQuestionSchema2 = Yup.object({
  name: Yup.string().required('This value is Required'),
  options: Yup.array().of(
    Yup.object().shape({
      serial: Yup.string().required('This value is Required'),
      name: Yup.string().required('This value is Required'),
      // image: Yup.string().required("This value is Required"),
    })
  ),
  dependingQuetion: Yup.string().required('This value is Required'),
  dependingOption: Yup.string().required('This value is Required'),
  questionType: Yup.string().required('This value is Required'),
});

export {
  createQuestionSchema,
  createQuestionSchema2,
  EditQuestionSchema,
  EditQuestionSchema2,
};
