import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Input, Label } from 'reactstrap';
import { IMG_API } from '../../helpers/api_helper';
import { postData } from '../../helpers/backend_helper';
import { addActivation } from '../../store/actions';
import CustomInput2 from '../Common/CustomInput2';
import { printToLetter } from '../Helpers/Custom/printNumberToLetter';
import TextError from '../InputComponent/TextError';
import {
  createQuestionSchema2,
  EditQuestionSchema2,
} from '../Schemas/AddQuestionSchema';

const initialValues = {
  name: '',
  dependingQuetion: '',
  dependingOption: '',
  questionType: '',
  options: [
    {
      serial: 'A',
      name: '',
      image: '',
      id: '',
    },
  ],
};
const optionTypes = [
  {
    name: 'Single Choice',
    value: 'single',
  },
  {
    name: 'Multiple Choice',
    value: 'multiple',
  },
];

const StepTwo = ({ edit, isOpen, toggle }) => {
  console.log(`StepTwo ~ isOpen`, isOpen, toggle);
  const [values, setValues] = useState(null);
  const { id } = useParams();
  const [questionSerial, setQuestionSerial] = useState(null);
  let dispatch = useDispatch();
  const {
    activations,
    activationDetails,
    adding,
    loading,
    authtoken,
    questions,
  } = useSelector((store) => ({
    authtoken: store.Login.token,
    users: store?.UserReducer?.users,
    activations: store.ActivationReducer.activations,
    loading: store.ActivationReducer.loading,
    activationDetails: store.ActivationReducer.activationDetails,
    questions: store.ActivationReducer.activationDetails?.questions,
    adding: store.ActivationReducer.adding,
    editActivationInfo: store.ActivationReducer.editActivationInfo,
  }));
  console.log(`activations`, activations, activationDetails);
  const findQuestion = questions?.find((data) => data?._id === isOpen);
  const findIndex = questions?.findIndex((data) => data?._id === isOpen);
  useEffect(() => {
    if (edit) {
      const oldValues = { ...values };
      oldValues.name = findQuestion?.name;
      oldValues.dependingQuetion = findQuestion?.dependingQuetion || '';
      oldValues.dependingOption = findQuestion?.dependingOption || '';
      oldValues.questionType = findQuestion?.questionType;
      oldValues.options = findQuestion?.options;
      setQuestionSerial(findQuestion?.serial);
      setValues(oldValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findQuestion]);

  const onSubmit = (values, { resetForm }) => {
    const {
      questionType,
      dependingQuetion,
      dependingOption,
      options,
      name,
      ...rest
    } = values;
    let data;
    if (!edit) {
      data = {
        ...rest,
        _id: activationDetails?.activation?._id,
        questions: {
          name,
          questionType,
          dependingQuetion,
          dependingOption,
          serial: activationDetails?.questions?.length + 1,
          options: options.map((option) => {
            return {
              serial: option.serial,
              name: option.name,
              image: option.image,
            };
          }),
          image: options.map((option) => {
            return {
              id: option.id,
            };
          }),
        },
      };
    } else {
      data = {
        name,
        questionType,
        serial: questionSerial,
        options: options.map((option) => {
          return {
            serial: option.serial,
            name: option.name,
            image: option.image,
          };
        }),
      };
      if (findIndex !== 0) {
        data.dependingQuetion = dependingQuetion;
        data.dependingOption = dependingOption;
      }
    }
    console.log(`data`, data);
    const option = {
      authtoken,
      resetForm,
      id,
      questionId: isOpen,
      toggle,
    };
    dispatch(addActivation(data, option));
  };

  const uploadImage = async (file, name, setFieldValue) => {
    const url = `/activation/dummyimage`;
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('name', file.name);
    const response = await postData(url, formData, authtoken);
    if (response.image) {
      setFieldValue(name + '.image', response.image.url || '');
      setFieldValue(name + '.id', response.image._id || '');
    }
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={values || initialValues}
        validationSchema={
          findIndex === 0 && edit ? EditQuestionSchema2 : createQuestionSchema2
        }
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, setFieldTouched }) => (
          <Form className="mt-2">
            {console.log(values)}
            {findIndex === 0 && edit ? null : (
              <Row>
                <Col md="12 mb-2">
                  <Field
                    name="dependingQuetion"
                    type="select"
                    component={CustomInput2}
                    label={'If Question'}
                  >
                    <option value="">Select Question</option>
                    {activationDetails?.questions?.length > 0
                      ? activationDetails?.questions?.map((option, idx) => (
                          <option key={idx} value={option?._id}>
                            {option?.name}
                          </option>
                        ))
                      : null}
                  </Field>
                </Col>
                {values?.dependingQuetion ? (
                  <Col md="12 mb-2">
                    <Field
                      name="dependingOption"
                      type="select"
                      component={CustomInput2}
                      label={'Question Option'}
                      placeholder="Select Question Option"
                    >
                      <option value="">Select Question Option</option>
                      {activationDetails?.questions?.length > 0
                        ? activationDetails?.questions
                            ?.find(
                              (data) => data?._id === values?.dependingQuetion
                            )
                            ?.options?.map((option, idx) => (
                              <option key={idx} value={option?.value}>
                                {option?.name}
                              </option>
                            ))
                        : null}
                    </Field>
                  </Col>
                ) : null}
              </Row>
            )}
            <Col md="12 mb-2">
              <Field
                name="questionType"
                type="select"
                component={CustomInput2}
                label={'Question Type'}
                placeholder="Select Question Type"
              >
                <option value="">Select Question Type</option>
                {optionTypes?.map((option, idx) => (
                  <option key={idx} value={option?.value}>
                    {option?.name}
                  </option>
                ))}
              </Field>
            </Col>

            <Col md="12 mb-2">
              <Field
                name="name"
                label={`Question Name`}
                type="text"
                component={CustomInput2}
                placeholder="Type name"
              />
            </Col>

            <Col md="12 mb-2">
              <FieldArray
                name="options"
                render={(arrayHelpers) => (
                  <div>
                    {values?.options?.map((value, index) => (
                      <React.Fragment key={index}>
                        <Label>{`Option ${printToLetter(index + 1)}`}</Label>
                        <Row className="mb-2 position-relative">
                          <Col md="6">
                            <Field
                              name={`options.${index}.name`}
                              type={'text'}
                              // label={`Option ${printToLetter(index + 1)}`}
                              component={CustomInput2}
                              placeholder="Type option"
                            />
                            <ErrorMessage
                              name={`options.${index}.name`}
                              component={TextError}
                            />
                          </Col>
                          <Col md="6">
                            <Field name={`options.${index}.image`} type="file">
                              {({ field }) => (
                                <React.Fragment>
                                  <Input
                                    type="file"
                                    name={`options.${index}.image`}
                                    onBlur={(e) => {
                                      setFieldTouched(
                                        `options.${index}.image`,
                                        true
                                      );
                                    }}
                                    onChange={(e) => {
                                      const { files } = e.target;
                                      setFieldTouched(
                                        `options.${index}.image`,
                                        true
                                      );
                                      if (files.length > 0) {
                                        uploadImage(
                                          files[0],
                                          `options.${index}`,
                                          setFieldValue
                                        );
                                      }
                                    }}
                                  />
                                </React.Fragment>
                              )}
                            </Field>
                            <ErrorMessage
                              name={`options.${index}.image`}
                              component={TextError}
                            />
                            {values.options?.[index]?.image && edit && (
                              <img
                                src={
                                  IMG_API + '/' + values.options?.[index]?.image
                                }
                                alt="img"
                                width={'50px'}
                              />
                            )}
                          </Col>
                          {index > 0 ? (
                            <div
                              className="position-absolute"
                              style={{
                                top: '-45%',
                                width: 'initial',
                                right: '0',
                              }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <button
                                className="btn-sm removeShadow"
                                style={{
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  fontSize: '18px',
                                }}
                              >
                                <i className="bx bx-trash text-danger"></i>
                              </button>
                            </div>
                          ) : null}
                        </Row>
                      </React.Fragment>
                    ))}
                    {edit ? null : (
                      <button
                        className="btn"
                        type="button"
                        style={{ color: '#058DE0', paddingLeft: '0px' }}
                        onClick={() =>
                          arrayHelpers.push({
                            name: '',
                            image: '',
                            id: '',
                            serial: printToLetter(values?.options.length + 1),
                          })
                        }
                      >
                        <i className="bx bx-plus"></i> Add Another Option
                      </button>
                    )}
                  </div>
                )}
              />
            </Col>

            <div className="text-end mt-4">
              <button
                className="btn btn-info"
                disabled={adding || loading}
                type="submit"
              >
                {adding
                  ? 'Submitting...'
                  : edit
                  ? 'Update Question'
                  : 'Add More Question'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default StepTwo;
