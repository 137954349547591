import {
  GET_ATTENDANCE,
  GET_ATTENDANCE_FAIL,
  GET_ATTENDANCE_SUCCESS,
} from "./actionTypes";

export const getAttendance = (
  authtoken,
  orgId,
  userId,
  fromDate,
  handleList,
  currentPage
) => ({
  type: GET_ATTENDANCE,
  payload: {
    authtoken,
    orgId,
    userId,
    fromDate,
    handleList,
    currentPage,
  },
});

export const getAttendanceSuccess = (data) => ({
  type: GET_ATTENDANCE_SUCCESS,
  payload: { data },
});

export const getAttendanceFail = (error) => ({
  type: GET_ATTENDANCE_FAIL,
  payload: error,
});
