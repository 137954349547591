import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BreadcrumbDashboard from "../../Breadcrumb/BreadcrumbDashboard";
import useExcelReader from "../../Hooks/useExcelReader";
import { getTopBanner } from "../../store/actions";
import "./dashboard.scss";
import Organization from "./DashboardCharts/Organization";
import Survey from "./DashboardCharts/Survey";
import org from "./img/org.svg";
import people from "./img/people.svg";
import question from "./img/question.svg";
import survey from "./img/survey.svg";

const Dashboard = () => {
  let dispatch = useDispatch();
  const history = useHistory();
  const { items, readExcel } = useExcelReader();

  const [results, setResults] = useState([]);
  const [isDone, setIsDone] = useState(false);

  const { authtoken, topBanner, role } = useSelector((state) => ({
    authtoken: state.Login.token,
    topBanner: state.DashboardReducer.topBanner,
    role: state.Login.userrole,
  }));
  useEffect(() => {
    dispatch(getTopBanner(authtoken, role));
  }, []);
  console.log("topBanner", topBanner);
  return (
    <Container fluid>
      <BreadcrumbDashboard leftTitle="Dashboard" />

      <div className="dashboard-body-top-content-header">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between text-center">
              <div></div>
              {role == "ADMIN" ? (
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={org} alt="Icon" />
                    <p className="mt-3 ms-2 header-text-color">Organization</p>
                  </div>
                  <h6>{topBanner?.data?.user?.org}</h6>
                </div>
              ) : (
                <></>
              )}

              <div>
                <div className="d-flex justify-content-center">
                  <img src={people} alt="Icon" />
                  <p className="mt-3 ms-2 header-text-color">BP</p>
                </div>
                <h6>
                  {role == "ADMIN"
                    ? topBanner?.data?.user?.bp
                    : topBanner?.data?.totalBP}
                </h6>
              </div>
              <div>
                <div className="d-flex justify-content-center">
                  <img src={survey} alt="Icon" />
                  <p className="mt-3 ms-2 header-text-color">Activation</p>
                </div>
                <h6>{topBanner?.data?.totalActivation}</h6>
              </div>
              <div>
                <div className="d-flex justify-content-center">
                  <img src={question} alt="Icon" />
                  <p className="mt-3 ms-2 header-text-color">Question</p>
                </div>
                <h6>{topBanner?.data?.totalQuestion}</h6>
              </div>
              <div>
                <div className="d-flex justify-content-center">
                  <img src={survey} alt="Icon" />
                  <p className="mt-3 ms-2 header-text-color">Survey</p>
                </div>
                <h6>{topBanner?.data?.totalSurvey}</h6>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-data-all-information-bottom-content mb-5">
        <Row>
          <Col lg={6}>
            <Survey />
          </Col>
          <Col lg={6}>
            <Organization />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Dashboard;
