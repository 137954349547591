import {
  GET_ALL_SURVEY_FAIL,
  GET_ALL_SURVEY_SUCCESS,
  GET_SURVEY_DETAILS_FAIL,
  GET_SURVEY_DETAILS_SUCCESS,
} from "./actionTypes";
const INIT_STATE = {
  surveys: [],
  surveyDetails: [],
  surveyLoading: true,
  getSurveyLoading: true,
  surveyDetailsLoading: true,
};

const SurveyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_SURVEY_SUCCESS:
      return {
        ...state,
        surveys: action.payload,
        getSurveyLoading: false,
      };
    case GET_ALL_SURVEY_FAIL:
      return {
        ...state,
        getSurveyLoading: false,
      };

    case GET_SURVEY_DETAILS_SUCCESS:
      return {
        ...state,
        surveyDetails: action.payload,
        surveyDetailsLoading: false,
      };
    case GET_SURVEY_DETAILS_FAIL:
      return {
        ...state,
        surveyDetailsLoading: false,
      };

    default:
      return state;
  }
};

export default SurveyReducer;
