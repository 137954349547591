export const GET_TOP_BANNER = "GET_TOP_BANNER";
export const GET_TOP_BANNER_SUCCESS = "GET_TOP_BANNER_SUCCESS";
export const GET_TOP_BANNER_FAIL = "GET_TOP_BANNER_FAIL";

export const GET_SURVEY = "GET_SURVEY";
export const GET_SURVEY_SUCCESS = "GET_SURVEY_SUCCESS";
export const GET_SURVEY_FAIL = "GET_SURVEY_FAIL";

export const GET_ORG = "GET_ORG";
export const GET_ORG_SUCCESS = "GET_ORG_SUCCESS";
export const GET_ORG_FAIL = "GET_ORG_FAIL";

export const GET_ORG_BP = "GET_ORG_BP";
export const GET_ORG_BP_SUCCESS = "GET_ORG_BP_SUCCESS";
export const GET_ORG_BP_FAIL = "GET_ORG_BP_FAIL";
