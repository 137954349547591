import {
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  EMPTY_EDIT_USER_INFO,
  GET_ALL_USER_FAIL,
  GET_ALL_USER_SUCCESS,
  GET_ORG_USER_FAIL,
  GET_ORG_USER_SUCCESS,
  GET_USER_DETAILS_FAIL,
  GET_USER_DETAILS_SUCCESS,
  STORE_USER_DATA,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  users: [],
  orgUser: [],
  superVisor: [],
  userDetails: [],
  userLoading: true,
  editUserInfo: [],
  getUserLoading: true,
  getOrgUserLoading: true,
  superVisorLoading: true,
  userDetailsLoading: true,
  loading: true,
};

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        userLoading: false,
      };
    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        userLoading: false,
      };
    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        users: action.payload,
        getUserLoading: false,
      };
    case GET_ALL_USER_FAIL:
      return {
        ...state,
        getUserLoading: false,
      };
    case GET_ORG_USER_SUCCESS:
      return {
        ...state,
        orgUser: action.payload,
        getOrgUserLoading: false,
      };
    case GET_ORG_USER_FAIL:
      return {
        ...state,
        getOrgUserLoading: false,
      };

    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        userDetailsLoading: false,
      };
    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        userDetailsLoading: false,
      };

    //EDIT USER
    case STORE_USER_DATA:
      return {
        ...state,
        editUserInfo: action.payload,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userData: state.user.map((user) =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false,
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case EMPTY_EDIT_USER_INFO:
      return {
        ...state,
        editUserInfo: [],
      };
    default:
      return state;
  }
};

export default UserReducer;
