import { call, delay, put, takeEvery } from "redux-saga/effects";
import {
  getSurveyData,
  getSurveyDetailsData,
} from "../../helpers/api_helper.js";
import {
  getSurveyDetailsFail,
  getSurveyDetailsSuccess,
  getSurveysFail,
  getSurveysSuccess,
} from "./actions";
import { GET_ALL_SURVEY, GET_SURVEY_DETAILS } from "./actionTypes";

function* fetchSurveys({
  payload: {
    authtoken,
    orgId,
    bpUserId,
    status,
    fromDate,
    toDate,
    limit,
    pageNo,
  },
}) {
  try {
    delay(500);
    const response = yield call(
      getSurveyData,
      authtoken,
      orgId,
      bpUserId,
      status,
      fromDate,
      toDate,
      limit,
      pageNo
    );

    yield put(getSurveysSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getSurveysFail(error));
    console.log("hi");
  }
}

function* fetchSurveyDetails({ payload: { authtoken, id } }) {
  try {
    const response = yield call(getSurveyDetailsData, authtoken, id);
    console.log("line 15", response);
    yield put(getSurveyDetailsSuccess(response));
  } catch (error) {
    yield put(getSurveyDetailsFail(error));
    console.log("hi");
  }
}

function* UserSaga() {
  yield takeEvery(GET_ALL_SURVEY, fetchSurveys);

  yield takeEvery(GET_SURVEY_DETAILS, fetchSurveyDetails);
}

export default UserSaga;
