import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Col, Form, Input, Row, Spinner } from "reactstrap";
import {
  addUser,
  emptyEditUser,
  getOrgUser,
  getUsers,
  storeUserData,
  updateUser,
} from "../../store/actions";
import CustomInput from "../Common/CustomInput";
import CardComponent from "../Layout/CardComponent";
import CustomTable from "../Layout/CustomTable";
import InnerLayer from "../Layout/InnerLayer";
import NoTableData from "../Layout/NoTableData";
import { addUserSchema } from "../Schemas/AddUser.schema";
import { updateUserSchema } from "../Schemas/UpdateUser.schema";

const initialValues = {
  name: "",
  employeeId: "",
  email: "",
  password: "",
  passwordConfirm: "",
  role: "",
  phoneNumber: "",
};

const renderTooltipView = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    View Details
  </Tooltip>
);
const renderTooltipEdit = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Edit
  </Tooltip>
);

const User = ({ history }) => {
  let dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [handleList, setHandleList] = useState(10);
  const [userRoleE, setUserRoleE] = useState("All");
  const [userRoleOrg, setUserRoleOrg] = useState("BP");
  const [orgId, setOrgId] = useState("All");
  const [productStockLoading, setProductStockLoading] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const [orgCheck, setOrgCheck] = useState(true);
  const [role, setRole] = useState("");
  const [org, setOrg] = useState("");
  const [singleUserData, setSingleUserData] = useState(null);

  const handleEditUser = (data) => {
    dispatch(storeUserData("singleUser", data));
  };

  const userRoles = [
    {
      name: "ADMIN",
      value: "ADMIN",
    },
    {
      name: "ORG",
      value: "ORG",
    },
    {
      name: "BP",
      value: "BP",
    },
  ];

  const {
    authtoken,
    users,
    getUserLoading,
    loginInfo,
    orgUser,
    editUserInfo,
    userrole,
    userId,
    loading,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    userrole: state.Login.userrole,
    userId: state.Login.warehouse,
    loginInfo: state.Login,
    users: state?.UserReducer?.users,
    orgUser: state?.UserReducer?.orgUser,
    getUserLoading: state?.UserReducer?.getUserLoading,
    editUserInfo: state?.UserReducer?.editUserInfo,
    loading: state?.UserReducer?.loading,
  }));

  const tableHead = [
    "Sl. No.",
    "Employee ID",
    "Name",
    "Email",
    "Phone No.",
    "Org Name",
    "Role",
    userrole == "ADMIN" ? "Action" : "",
  ];
  useEffect(() => {
    userrole == "ADMIN" ? (
      dispatch(getUsers(authtoken, userRoleE, orgId, handleList, currentPage))
    ) : userrole == "ORG" ? (
      dispatch(
        getUsers(authtoken, userRoleOrg, userId, handleList, currentPage)
      )
    ) : (
      <></>
    );
  }, [orgId, userRoleOrg, userId, userRoleE, handleList, currentPage]);

  useEffect(() => {
    dispatch(getOrgUser(authtoken, "ORG", orgId, handleList, currentPage));
  }, []);

  let totalPageNumber = Math.ceil(users?.data?.data?.totalLength / handleList);

  const handleRole = (value) => {
    if (value) {
      setUserRoleE(value.value);
    } else {
      setUserRoleE("All");
    }
  };
  const handleOrg = (value) => {
    if (value) {
      setRole(value.value);
      value.value === "BP" ? setOrgCheck(false) : setOrgCheck(true);
    }
  };
  const handleOrgVal = (value) => {
    if (value) {
      setOrg(value._id);
    }
  };
  console.log("setOrg", org);

  const handleOrgFilter = (value) => {
    if (value) {
      setOrgId(value._id);
    } else {
      setOrgId("All");
    }
  };

  useEffect(() => {
    if (editUserInfo) {
      const data = { ...singleUserData };
      data.name = editUserInfo?.data?.name;
      data.employeeId = editUserInfo?.data?.employeeId;
      data.phoneNumber = editUserInfo?.data?.phoneNumber;
      data.role = editUserInfo?.data?.role;
      data.email = editUserInfo?.data?.email;
      data.org = editUserInfo?.data?.org?._id;

      setSingleUserData(data);
    }
  }, [editUserInfo]);

  const onSubmit = (values) => {
    const data = { ...values, org, role };

    let obj = {};
    obj.name = data?.name;
    obj.email = data?.email;
    obj.phoneNumber = data?.phoneNumber;
    obj.role = data.role ? data.role : editUserInfo?.data?.role;
    obj.employeeId = data?.employeeId;
    obj.org = org ? org : null;
    if (data?.password !== undefined) {
      obj.password = data?.password;
      obj.passwordConfirm = data?.passwordConfirm;
    }
    console.log("loading", loading);
    if (editUserInfo == undefined || editUserInfo.length == 0) {
      dispatch(addUser(obj, history, authtoken));

      setCreateUser(false);
    } else {
      dispatch(updateUser(editUserInfo.data._id, obj, authtoken, history));
      if (loading == true) {
        setEditUser(false);
      }
    }
  };

  const AddUser = useFormik({
    enableReinitialize: true,
    initialValues: singleUserData || initialValues,
    validationSchema: editUserInfo ? updateUserSchema : addUserSchema,
    onSubmit,
  });
  const createUserModal = () => {
    dispatch(emptyEditUser());
    setCreateUser(true);
  };
  console.log("editUserInfo", editUserInfo);

  const handleListData = (e) => {
    setHandleList(e.target.value);
  };
  return (
    <React.Fragment>
      <InnerLayer
        title="Users"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Users"}
        subTitle={"User List"}
        onAddHandler={() => createUserModal()}
        buttonText="Add User"
        isButton={userrole == "ADMIN" ? true : false}
      >
        <Row>
          <CardComponent className="user_table_card">
            <Row className="mb-3 table-header-padding">
              <div className="d-flex d-flex-blockCustom justify-content-end">
                {userrole == "ADMIN" ? (
                  <Col className="ms-2" md={2}>
                    <Select
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Organization"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={orgUser?.data?.data?.users}
                      onChange={handleOrgFilter}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                {userrole == "ADMIN" ? (
                  <Col className="ms-2" md={2}>
                    <Select
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Role"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={userRoles}
                      onChange={handleRole}
                    />
                  </Col>
                ) : (
                  <></>
                )}

                <Col className="ms-2" md={2}>
                  <Input type="select" onChange={handleListData}>
                    {/* <option defaultValue>Select...</option> */}
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Input>
                </Col>
              </div>
            </Row>
            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={[1, 2, 3]}
                pageNo={totalPageNumber}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
              >
                {getUserLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </div>
                  </tr>
                ) : users?.data?.data?.users?.length > 0 ? (
                  users?.data?.data?.users?.map((data, idx) => (
                    <tr>
                      <th scope="row" style={{ paddingLeft: "25px" }}>
                        {idx + 1}
                      </th>
                      <td>{data?.employeeId}</td>
                      <td>{data?.name}</td>
                      <td>{data?.email}</td>
                      <td>{data?.phoneNumber}</td>
                      <td>{data?.org?.name}</td>
                      <td>{data?.role}</td>

                      <td>
                        <div className="d-flex justify-content-start align-items-center">
                          {/* <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipView}
                          >
                            <button
                              className="btn btn-primary btn-sm me-2"
                              style={{ borderRadius: "10px" }}
                              // onClick={() => handleStoke(data)}
                            >
                              <i className="bx bx-show mt-1"></i>
                            </button>
                          </OverlayTrigger> */}
                          {userrole == "ADMIN" ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltipEdit}
                            >
                              <button
                                className="btn btn-primary btn-sm me-2"
                                style={{ borderRadius: "10px" }}
                                onClick={() => {
                                  setEditUser(true);
                                  handleEditUser(data);
                                }}
                              >
                                <i className="bx bx-edit mt-1"></i>
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan="8"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>No data Available</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
              {/* modal for create */}
              <Modal
                size="lg"
                show={createUser}
                onHide={() => setCreateUser(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header
                  closeButton
                  style={{ borderBottom: "none" }}
                ></Modal.Header>
                <Modal.Body style={{ padding: "3px 25px" }}>
                  <h4 className="mb-4" style={{ color: "#058DE0" }}>
                    Add User
                  </h4>
                  <Form
                    className="needs-validation col-md-12"
                    onSubmit={AddUser.handleSubmit}
                  >
                    <Row className="mb-4">
                      <Col md="6 mb-2">
                        <CustomInput
                          name={"name"}
                          type={"text"}
                          label={"Full Name"}
                          placeholder={"Type Name"}
                          validationType={AddUser}
                          defaultValue=""
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <CustomInput
                          name={"employeeId"}
                          type={"text"}
                          label={"Employee ID"}
                          placeholder={"Type Employee ID"}
                          validationType={AddUser}
                          defaultValue=""
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <CustomInput
                          name={"email"}
                          type={"text"}
                          label={"Email"}
                          placeholder={"example@gmail.com"}
                          validationType={AddUser}
                          defaultValue=""
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <CustomInput
                          name={"phoneNumber"}
                          type={"text"}
                          label={"Phone no."}
                          placeholder={"Type Phone no."}
                          validationType={AddUser}
                          defaultValue=""
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <label>Select Role</label>
                        <Select
                          className="mb-2"
                          classNamePrefix="select2-selection"
                          placeholder="Select Role"
                          cacheOptions
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.value}
                          isClearable
                          options={userRoles}
                          onChange={handleOrg}
                          required
                          defaultValue=""
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <CustomInput
                          name={"password"}
                          type={"text"}
                          label={"Password"}
                          placeholder={"********"}
                          validationType={AddUser}
                          required
                          defaultValue=""
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <label>Organization</label>
                        <Select
                          name=""
                          className="mb-2"
                          classNamePrefix="select2-selection"
                          placeholder="Select Organization"
                          cacheOptions
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.value}
                          isClearable
                          options={orgUser?.data?.data?.users}
                          onChange={handleOrgVal}
                          isDisabled={orgCheck}
                          defaultValue=""
                        />
                      </Col>

                      <Col md="6 mb-2">
                        <CustomInput
                          name={"passwordConfirm"}
                          type={"text"}
                          label={"Confirm Password"}
                          placeholder={"********"}
                          validationType={AddUser}
                          required
                          defaultValue=""
                        />
                      </Col>

                      <div className="text-end mt-4">
                        <button className="btn btn-primary" type="submit">
                          Add User
                        </button>
                      </div>
                    </Row>
                  </Form>
                </Modal.Body>
              </Modal>
              {/* edit */}
              <Modal
                size="lg"
                show={editUser}
                onHide={() => setEditUser(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header
                  closeButton
                  style={{ borderBottom: "none" }}
                ></Modal.Header>
                <Modal.Body style={{ padding: "3px 25px" }}>
                  <h4 className="mb-4" style={{ color: "#058DE0" }}>
                    Update User
                  </h4>
                  <Form
                    className="needs-validation col-md-12"
                    onSubmit={AddUser.handleSubmit}
                  >
                    <Row className="mb-4">
                      <Col md="6 mb-2">
                        <CustomInput
                          name={"name"}
                          type={"text"}
                          label={"Full Name"}
                          placeholder={"Type Name"}
                          validationType={AddUser}
                          defaultValue={editUserInfo?.data?.name}
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <CustomInput
                          name={"employeeId"}
                          type={"text"}
                          label={"Employee ID"}
                          placeholder={"Type Employee ID"}
                          defaultValue={editUserInfo?.data?.employeeId}
                          validationType={AddUser}
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <CustomInput
                          name={"email"}
                          type={"text"}
                          label={"Email"}
                          placeholder={"example@gmail.com"}
                          defaultValue={editUserInfo?.data?.email}
                          validationType={AddUser}
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <CustomInput
                          name={"phoneNumber"}
                          type={"text"}
                          label={"Phone no."}
                          placeholder={"Type Phone no."}
                          defaultValue={editUserInfo?.data?.phoneNumber}
                          validationType={AddUser}
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <label>Select Role</label>
                        <Select
                          className="mb-2"
                          classNamePrefix="select2-selection"
                          placeholder="Select Role"
                          cacheOptions
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.value}
                          isClearable
                          options={userRoles}
                          onChange={handleOrg}
                          defaultValue={userRoles?.filter(
                            (dta) => dta.value === editUserInfo?.data?.role
                          )}
                          required
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <CustomInput
                          name={"password"}
                          type={"text"}
                          label={"Password"}
                          placeholder={"********"}
                          validationType={AddUser}
                          defaultValue={editUserInfo?.data?.password}
                        />
                      </Col>
                      <Col md="6 mb-2">
                        <label>Organization</label>
                        <Select
                          name=""
                          className="mb-2"
                          classNamePrefix="select2-selection"
                          placeholder="Select Organization"
                          cacheOptions
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.value}
                          isClearable
                          options={orgUser?.data?.data?.users}
                          onChange={handleOrgVal}
                          // isDisabled={orgCheck}
                          defaultInputValue={orgUser?.data?.data?.users?.filter(
                            (dta) => dta._id === editUserInfo?.data?.org?._id
                          )}
                        />
                      </Col>

                      <Col md="6 mb-2">
                        <CustomInput
                          name={"passwordConfirm"}
                          type={"text"}
                          label={"Confirm Password"}
                          placeholder={"********"}
                          validationType={AddUser}
                          defaultValue={editUserInfo?.data?.confirmPassword}
                        />
                      </Col>

                      <div className="text-end mt-4">
                        <button className="btn btn-primary" type="submit">
                          Update User
                        </button>
                      </div>
                    </Row>
                  </Form>
                </Modal.Body>
              </Modal>
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  );
};

User.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(User);
