import { call, delay, put, takeEvery } from "redux-saga/effects";
import { getAttendanceData } from "../../helpers/api_helper";
import { getAttendanceFail, getAttendanceSuccess } from "./actions";
import { GET_ATTENDANCE } from "./actionTypes";

function* fetchAttendance({
  payload: { authtoken, orgId, userId, fromDate, handleList, currentPage },
}) {
  try {
    delay(500);

    const response = yield call(
      getAttendanceData,
      authtoken,
      orgId,
      userId,
      fromDate,
      handleList,
      currentPage
    );
    console.log("fetchAttendance", response);
    yield put(getAttendanceSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getAttendanceFail(error));
  }
}

function* AttendanceSaga() {
  yield takeEvery(GET_ATTENDANCE, fetchAttendance);
}

export default AttendanceSaga;
