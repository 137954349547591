import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { Col, Input, Label, Row } from 'reactstrap';
import { IMG_API } from '../../helpers/api_helper';
import { postData } from '../../helpers/backend_helper';
import { addActivation } from '../../store/actions';
import CustomInput2 from '../Common/CustomInput2';
import { printToLetter } from '../Helpers/Custom/printNumberToLetter';
import TextError from '../InputComponent/TextError';
import {
  createQuestionSchema,
  EditQuestionSchema,
} from '../Schemas/AddQuestionSchema';
const optionTypes = [
  {
    name: 'Single Choice',
    value: 'single',
  },
  {
    name: 'Multiple Choice',
    value: 'multiple',
  },
];

const initialValues = {
  name: '',
  org: '',
  activationId: '',
  _id: '',
  qName: '',
  questionType: '',
  dependingQuetion: '',
  dependingOption: '',
  dummyImageId: '',
  image: '',
  options: [
    {
      serial: 'A',
      name: '',
      image: '',
      id: '',
    },
  ],
};

const StepOne = ({ edit, history }) => {
  const { id } = useParams();
  let dispatch = useDispatch();
  const [values, setValues] = useState(null);
  const { adding, loading, authtoken, users, activationDetails, activation } =
    useSelector((store) => ({
      authtoken: store.Login.token,
      users: store?.UserReducer?.users,
      questionDetails: store.ActivationReducer.questionDetails,
      loading: store.ActivationReducer.loading,
      activationDetails: store.ActivationReducer.activationDetails,
      activation: store.ActivationReducer.activationDetails?.activation,
      adding: store.ActivationReducer.adding,
      editActivationInfo: store.ActivationReducer.editActivationInfo,
    }));
  console.log(`activationDetails`, activation, activationDetails);
  useEffect(() => {
    if (edit) {
      const oldValues = { ...values };
      oldValues.activationId = activation?.activationId;
      oldValues.name = activation?.name;
      oldValues.org = activation?.org?._id;
      oldValues.image = activation?.image;
      oldValues.dummyImageId = activation?.dummyImageId || '1';
      setValues(oldValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activation]);
  const onSubmit = (values, { resetForm }) => {
    const {
      qName,
      questionType,
      dependingQuetion,
      dependingOption,
      options,
      ...rest
    } = values;
    let data;
    if (!edit) {
      data = {
        ...rest,
        questions: {
          name: qName,
          questionType,
          serial: 1,
          options: options.map((option) => {
            return {
              serial: option.serial,
              name: option.name,
              image: option.image,
            };
          }),
          image: options.map((option) => {
            return {
              id: option.id,
            };
          }),
        },
      };
    } else {
      data = {
        org: values.org,
        name: values.name,
        activationId: values.activationId,
        totalQuestion: activation?.question?.length,
      };
    }
    // console.log('data', data);
    const option = {
      authtoken,
      resetForm: edit ? history : resetForm,
      id,
    };
    dispatch(addActivation(data, option));
  };

  const uploadImage = async (file, name, setFieldValue, single) => {
    console.log(file, name, setFieldValue, single);
    const url = `/activation/dummyimage`;
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('name', file.name);
    const response = await postData(url, formData, authtoken);
    if (response.image) {
      setFieldValue(
        single ? 'image' : name + '.image',
        response.image.url || ''
      );
      setFieldValue(single ? name : name + '.id', response.image._id || '');
    }
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={values || initialValues}
        validationSchema={edit ? EditQuestionSchema : createQuestionSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, setFieldTouched, errors }) => (
          <Form className="mt-2">
            {/* {console.log('values', values, errors)} */}
            <Row>
              <Col md="6 mb-2">
                <Field
                  name="org"
                  type="select"
                  component={CustomInput2}
                  label={'Organization'}
                  placeholder="Select Organization"
                  // options={typeLoading ? data : []}
                  // disabled={edit}
                >
                  <option value="">Select Organization</option>
                  {users?.data?.data?.users?.length > 0
                    ? users?.data?.data?.users?.map((option, idx) => (
                        <option key={idx} value={option?._id}>
                          {option?.name}
                        </option>
                      ))
                    : null}
                </Field>
              </Col>
              <Col md="6 mt-4">
                <Field name={`dummyImageId`} type="file">
                  {({ field }) => (
                    <React.Fragment>
                      <Input
                        type="file"
                        name={`dummyImageId`}
                        onBlur={(e) => {
                          setFieldTouched(`dummyImageId`, true);
                        }}
                        onChange={(e) => {
                          const { files } = e.target;
                          setFieldTouched(`dummyImageId`, true);
                          if (files.length > 0) {
                            uploadImage(
                              files[0],
                              `dummyImageId`,
                              setFieldValue,
                              true
                            );
                          }
                        }}
                      />
                    </React.Fragment>
                  )}
                </Field>
                <ErrorMessage name={`dummyImageId`} component={TextError} />
                {values?.image && edit && (
                  <img
                    src={IMG_API + '/' + values?.image}
                    alt="img"
                    width={'100px'}
                  />
                )}
              </Col>
            </Row>

            <Col md="12 mb-2">
              <Field
                name="name"
                label={'Activation Name'}
                type="text"
                component={CustomInput2}
                placeholder="Type name"
              />
            </Col>

            <Col md="12 mb-2">
              <Field
                name="activationId"
                label={'Activation Id'}
                type="text"
                component={CustomInput2}
                placeholder="Type Id"
              />
            </Col>
            {edit ? null : (
              <Row>
                <Col md="12 mb-2">
                  <Field
                    name="questionType"
                    type="select"
                    component={CustomInput2}
                    label={'Question Type'}
                    placeholder="Select Question Type"
                    // options={typeLoading ? data : []}
                    // disabled={edit}
                  >
                    <option value="">Select Question Type</option>
                    {optionTypes?.map((option, idx) => (
                      <option key={idx} value={option?.value}>
                        {option?.name}
                      </option>
                    ))}
                  </Field>
                </Col>

                <Col md="12 mb-2">
                  <Field
                    name="qName"
                    label={'Question'}
                    type="text"
                    component={CustomInput2}
                    placeholder="Type Question"
                  />
                </Col>

                <Col md="12 mb-2">
                  <FieldArray
                    name="options"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.options?.map((value, index) => (
                          <React.Fragment key={index}>
                            <Label>{`Option ${printToLetter(
                              index + 1
                            )}`}</Label>
                            <Row className="mb-2 position-relative">
                              <Col md="6">
                                <Field
                                  name={`options.${index}.name`}
                                  type={'text'}
                                  // label={`Option ${printToLetter(index + 1)}`}
                                  component={CustomInput2}
                                  placeholder="Type option"
                                />
                                <ErrorMessage
                                  name={`options.${index}.name`}
                                  component={TextError}
                                />
                              </Col>
                              <Col md="6">
                                <Field
                                  name={`options.${index}.image`}
                                  type="file"
                                >
                                  {({ field }) => (
                                    <React.Fragment>
                                      <Input
                                        type="file"
                                        name={`options.${index}.image`}
                                        onBlur={(e) => {
                                          setFieldTouched(
                                            `options.${index}.image`,
                                            true
                                          );
                                        }}
                                        onChange={(e) => {
                                          const { files } = e.target;
                                          setFieldTouched(
                                            `options.${index}.image`,
                                            true
                                          );
                                          if (files.length > 0) {
                                            // setFieldValue(
                                            //   `options.${index}.image`,
                                            //   files[0].name || ""
                                            // );
                                            uploadImage(
                                              files[0],
                                              `options.${index}`,
                                              setFieldValue
                                            );
                                          }
                                        }}
                                      />
                                    </React.Fragment>
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={`options.${index}.image`}
                                  component={TextError}
                                />
                              </Col>
                              {index > 0 ? (
                                <div
                                  className="position-absolute"
                                  style={{
                                    top: '-45%',
                                    width: 'initial',
                                    right: '0',
                                  }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <button
                                    className="btn-sm removeShadow"
                                    style={{
                                      backgroundColor: 'transparent',
                                      border: 'none',
                                      fontSize: '18px',
                                    }}
                                  >
                                    <i className="bx bx-trash text-danger"></i>
                                  </button>
                                </div>
                              ) : null}
                            </Row>
                          </React.Fragment>
                        ))}
                        <button
                          className="btn"
                          type="button"
                          style={{ color: '#058DE0', paddingLeft: '0px' }}
                          onClick={() =>
                            arrayHelpers.push({
                              name: '',
                              image: '',
                              id: '',
                              serial: printToLetter(values?.options.length + 1),
                            })
                          }
                        >
                          <i className="bx bx-plus"></i> Add Another Option
                        </button>
                      </div>
                    )}
                  />
                </Col>
              </Row>
            )}
            <div className="text-end mt-4">
              <button
                className="btn btn-info"
                disabled={adding || loading}
                type="submit"
              >
                {adding
                  ? 'Submitting...'
                  : edit
                  ? 'Update activation'
                  : 'Add More Question'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withRouter(StepOne);
