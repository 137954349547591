import {
  ADD_ACTIVATION,
  ADD_ACTIVATION_FAIL,
  ADD_ACTIVATION_SUCCESS,
  GET_ACTIVATION_DETAILS,
  GET_ACTIVATION_FAIL,
  GET_ACTIVATION_SUCCESS,
  GET_ALL_ACTIVATION_FAIL,
  GET_ALL_ACTIVATION_SUCCESS,
  GET_QUESTION_FAIL,
  GET_QUESTION_SUCCESS,
  STORE_ACTIVATION_DATA,
  STORE_QUESTION_DATA,
  UPDATE_ACTIVATION_FAIL,
  UPDATE_ACTIVATION_SUCCESS,
  UPDATE_QUESTION_FAIL,
  UPDATE_QUESTION_SUCCESS,
} from "./actionTypes";
const INIT_STATE = {
  activations: {},
  questionDetails: [],
  activationDetails: {},
  adding: false,
  editActivationInfo: [],
  editQuestionInfo: [],
  loading: false,
};

const ActivationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_ACTIVATION:
      return {
        ...state,
        adding: true,
      };

    case ADD_ACTIVATION_SUCCESS:
      return {
        ...state,
        activations: action.payload,
        adding: false,
      };
    case ADD_ACTIVATION_FAIL:
      return {
        ...state,
        error: action.payload,
        adding: false,
      };
    case GET_ALL_ACTIVATION_SUCCESS:
      return {
        ...state,
        activations: action.payload,
        loading: false,
      };
    case GET_ALL_ACTIVATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_ACTIVATION_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case GET_ACTIVATION_SUCCESS:
      return {
        ...state,
        activationDetails: action.payload.data,
        loading: false,
      };
    case GET_ACTIVATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_QUESTION_SUCCESS:
      return {
        ...state,
        questionDetails: action.payload,
        loading: false,
      };
    case GET_QUESTION_FAIL:
      return {
        ...state,
        loading: false,
      };
    //EDIT activation

    case STORE_ACTIVATION_DATA:
      return {
        ...state,
        editActivationInfo: action.payload,
      };
    case UPDATE_ACTIVATION_SUCCESS:
      return {
        ...state,
        activationData: state.activation.map((activation) =>
          activation.id.toString() === action.payload.id.toString()
            ? { activation, ...action.payload }
            : activation
        ),
        loading: false,
      };

    case UPDATE_ACTIVATION_FAIL:
      return {
        ...state,
        error: action.payload,
        adding: false,
        loading: false,
      };
    //EDIT question

    case STORE_QUESTION_DATA:
      return {
        ...state,
        editQuestionInfo: action.payload,
      };
    case UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        questionData: state.question.map((question) =>
          question.id.toString() === action.payload.id.toString()
            ? { question, ...action.payload }
            : question
        ),
        loading: false,
      };

    case UPDATE_QUESTION_FAIL:
      return {
        ...state,
        error: action.payload,
        adding: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default ActivationReducer;
