import { Redirect } from "react-router-dom";
import Attendance from "../Components/Attendance/Attendance";
import Login from "../Components/Authentication/Login";
import Dashboard from "../Components/Dashboard/Dashboard";
import AddQuestion from "../Components/Question/AddQuestion";
import Question from "../Components/Question/Question";
import Survey from "../Components/Survey/Survey";
import SurveyDetail from "../Components/Survey/SurveyDetail";
import TargetSet from "../Components/TargetSet/TargetSet";
import User from "../Components/User/User";

const openRoute = [
  // { path: "/logout", component: Logout },
  { path: "/login", component: Login },

  // { path: "/register", component: Register },
];

const protectedRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/admin-dashboard" />,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/admin-dashboard",
    component: Dashboard,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/users",
    component: User,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/edit-user/:id",
    component: () => <User edit />,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/question",
    component: Question,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/add-question",
    component: AddQuestion,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/edit-question/:id",
    component: () => <AddQuestion edit />,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/target-set",
    component: TargetSet,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/survey",
    component: Survey,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/survey-detail/:id",
    component: SurveyDetail,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/attendance",
    component: Attendance,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/survey-details",
    component: Attendance,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/attendance",
    component: Attendance,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
];

export { openRoute, protectedRoute };
