import axios from "axios";
const REACT_APP_API = `https://apiactivation-staging.salesx.xyz/api/v1`;
export const IMG_API = `https://staging-brandactivation.sgp1.digitaloceanspaces.com`;
// const REACT_APP_API = "http://localhost:5000/api"
//user account managemnet
export const axiosUserLogin = async (employeeId, password) =>
  await axios.post(`${REACT_APP_API}/users/login`, { employeeId, password });

const axiosApi = axios.create({
  baseURL: REACT_APP_API,
});

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function image(url, data, authtoken) {
  return axiosApi
    .post(url, data, { headers: { Authorization: `Bearer ${authtoken}` } })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function patch(url, data, config) {
  return axiosApi
    .patch(url, data, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
export const axiosTeacherRegistration = async (
  role,
  firstName,
  lastName,
  email,
  phoneNumber,
  instituteName,
  password,
  confirmPassword
) =>
  await axios.post(`${REACT_APP_API}/registration-teacher`, {
    role,
    firstName,
    lastName,
    email,
    phoneNumber,
    instituteName,
    password,
    confirmPassword,
  });
//Student Registration
export const axiosStudentRegistration = async (
  role,
  firstName,
  lastName,
  email,
  phoneNumber,
  instituteName,
  password,
  confirmPassword
) =>
  await axios.post(`${REACT_APP_API}/registration-student`, {
    role,
    firstName,
    lastName,
    email,
    phoneNumber,
    instituteName,
    password,
    confirmPassword,
  });
//reset password
export const axiosResetPassword = async (
  accountActivateToken,
  email,
  password,
  confirmPassword
) =>
  await axios.patch(`${REACT_APP_API}/user/account-verify`, {
    accountActivateToken,
    email,
    password,
    confirmPassword,
  });
// //forget password
export const axiosForgetPassword = async (email) =>
  await axios.patch(`${REACT_APP_API}/forget-password`, { email });
//update profile
export const axiosUpdateProfile = async (
  authtoken,
  firstName,
  lastName,
  phoneNumber
) =>
  await axios.patch(
    `${REACT_APP_API}/update-profile`,
    { firstName, lastName, phoneNumber },
    { headers: { authtoken } }
  );
//update password
export const axiosUpdatePassword = async (
  authtoken,
  currentPassword,
  password,
  confirmPassword
) =>
  await axios.patch(
    `${REACT_APP_API}/update-password`,
    { currentPassword, password, confirmPassword },
    { headers: { authtoken } }
  );

//users
export const addUserData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/users`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const getUserData = async (authtoken, role, orgId, list, currentPage) =>
  await axios.get(
    `${REACT_APP_API}/users/user-list?&orgId=${orgId}&userRole=${
      role || ""
    }&limit=${list}&pageNo=${currentPage}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );
export const getUserDetailsData = async (authtoken, id) =>
  await axios.get(`${REACT_APP_API}/users/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
//edit user
export const updateUserData = async (id, data, authtoken) =>
  await axios.patch(`${REACT_APP_API}/users/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
//end users

//activation start

export const addActivationData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/activation`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const getActivationData = async (
  authtoken,
  orgId,
  status,
  list,
  currentPage
) =>
  await axios.get(
    `${REACT_APP_API}/activation/get-activations?orgId=${orgId}&limit=${list}&pageNo=${currentPage}&status=${status}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );
export const getActivationDetailsData = async (authtoken, id) =>
  await axios.get(`${REACT_APP_API}/activation/get-activation-by-id/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const getQuestionDetailsData = async (authtoken, id) =>
  await axios.get(`${REACT_APP_API}/activation/get-question-by-id/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });

export const updateActivationData = async (id, data, authtoken) =>
  await axios.patch(
    `${REACT_APP_API}/activation/update-activation/${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );

export const updateQuestionData = async (id, data, authtoken) =>
  await axios.patch(`${REACT_APP_API}/activation/question-update/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });

//activation end
// TARGET
export const axiosGetTarget = async (authtoken, laUserId) =>
  await axios.get(`${REACT_APP_API}/target/get-target-by-user/${laUserId}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const postTargetData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/target`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const updateTargetData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/target/update-user-target`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
// Attendance
export const getTargetAchievementData = async (authtoken, userId, year) =>
  await axios.get(
    `${REACT_APP_API}/target/target-achievement?userId=${userId}&year=${year}`,

    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );
// TARGET end
// Survey start
export const getSurveyData = async (
  authtoken,
  orgId,
  bpUserId,
  status,
  fromDate,
  toDate,
  limit,
  pageNo
) =>
  await axios.get(
    `${REACT_APP_API}/survey/get-survey-list?orgId=${orgId}&bpUserId=${bpUserId}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&limit=${limit}&pageNo=${pageNo}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );
export const getSurveyDetailsData = async (authtoken, id) =>
  await axios.get(`${REACT_APP_API}/survey/singleSurvey/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });

// Survey end
//dashboard
export const getData = (url, authtoken) =>
  get(url, { headers: { Authorization: `Bearer ${authtoken}` } });

// Attendance
export const getAttendanceData = async (
  authtoken,
  orgId,
  userId,
  fromDate,
  handleList,
  currentPage
) =>
  await axios.get(
    `${REACT_APP_API}/attendance/datewiseattendance?orgId=${orgId}&userId=${userId}&date=${fromDate}&limit=${handleList}&pageNo=${currentPage}`,

    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );
