import { all, fork } from "redux-saga/effects";
import ActivationSaga from "./Activation/saga";
import AttendanceSaga from "./Attendance/saga";
import DashboardSaga from "./Dashboard/saga";
import forgetPasswordSaga from "./ForgetPassword/saga";
import LoginSaga from "./login/saga";
import { default as registrationSaga } from "./registration/saga";
import SurveySaga from "./Survey/saga";
import TargetSaga from "./Target/saga";
import UserSaga from "./User/saga";
export default function* rootSaga() {
  yield all([fork(LoginSaga)]);
  yield all([fork(registrationSaga)]);
  yield all([fork(AttendanceSaga)]);
  yield all([fork(forgetPasswordSaga)]);
  yield all([fork(UserSaga)]);
  yield all([fork(ActivationSaga)]);
  yield all([fork(TargetSaga)]);
  yield all([fork(SurveySaga)]);
  yield all([fork(DashboardSaga)]);
}
