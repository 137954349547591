import {
  GET_SURVEY_DETAILS_FAIL,
  GET_SURVEY_DETAILS_SUCCESS,
  GET_SURVEY_DETAILS,
  GET_ALL_SURVEY_FAIL,
  GET_ALL_SURVEY_SUCCESS,
  GET_ALL_SURVEY,
} from "./actionTypes";

export const getSurveys = (
  authtoken,
  orgId,
  bpUserId,
  status,
  fromDate,
  toDate,
  limit,
  pageNo
) => ({
  type: GET_ALL_SURVEY,
  payload: {
    authtoken,
    orgId,
    bpUserId,
    status,
    fromDate,
    toDate,
    limit,
    pageNo,
  },
});

export const getSurveysSuccess = (data, authtoken) => ({
  type: GET_ALL_SURVEY_SUCCESS,
  payload: { data, authtoken },
});

export const getSurveysFail = (error) => ({
  type: GET_ALL_SURVEY_FAIL,
  payload: error,
});

export const getSurveyDetails = (authtoken, id) => ({
  type: GET_SURVEY_DETAILS,
  payload: { authtoken, id },
});

export const getSurveyDetailsSuccess = (data) => ({
  type: GET_SURVEY_DETAILS_SUCCESS,
  payload: { data },
});
export const getSurveyDetailsFail = (error) => ({
  type: GET_SURVEY_DETAILS_FAIL,
  payload: error,
});
