import {
  GET_ORG_BP_FAIL,
  GET_ORG_BP_SUCCESS,
  GET_ORG_FAIL,
  GET_ORG_SUCCESS,
  GET_SURVEY_FAIL,
  GET_SURVEY_SUCCESS,
  GET_TOP_BANNER_FAIL,
  GET_TOP_BANNER_SUCCESS,
} from "./actionTypes";
const INIT_STATE = {
  topBanner: [],
  SurveyData: [],
  orgData: [],
  orgBpData: [],

  topBannerLoading: true,
  SurveyDataLoading: true,
  orgLoading: true,
  orgBpLoading: true,
};
const DashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TOP_BANNER_SUCCESS:
      return {
        ...state,
        topBanner: action.payload,
        topBannerLoading: false,
      };
    case GET_TOP_BANNER_FAIL:
      return {
        ...state,
        error: action.payload,
        topBannerLoading: false,
      };
    case GET_SURVEY_SUCCESS:
      return {
        ...state,
        SurveyData: action.payload,
        SurveyDataLoading: false,
      };
    case GET_SURVEY_FAIL:
      return {
        ...state,
        error: action.payload,
        SurveyDataLoading: false,
      };
    case GET_ORG_SUCCESS:
      return {
        ...state,
        orgData: action.payload,
        orgLoading: false,
      };
    case GET_ORG_FAIL:
      return {
        ...state,
        error: action.payload,
        orgLoading: false,
      };

    case GET_ORG_BP_SUCCESS:
      return {
        ...state,
        orgBpData: action.payload,
        orgBpLoading: false,
      };
    case GET_ORG_BP_FAIL:
      return {
        ...state,
        error: action.payload,
        orgBpLoading: false,
      };

    default:
      return state;
  }
};
export default DashboardReducer;
