import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTargetAchievement } from "../../store/actions";

// import { Card, CardBody } from "reactstrap";
const AchievementChart = (props) => {
  const dispatch = useDispatch();
  const { authtoken, targetAchievement } = useSelector((state) => ({
    authtoken: state.Login.token,
    targetAchievement: state.TargetReducer.targetAchievement,
  }));

  useEffect(() => {
    dispatch(getTargetAchievement(authtoken, props.userId, props.year));
  }, [props.userId, props.year]);
  console.log("props.userId", props.userId);
  console.log("props.year", props.year);

  let actualTarget = [];
  let achievementTarget = [];
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  targetAchievement?.data?.data?.target?.map((data, i) => {
    actualTarget?.push(data?.actualTarget);
    achievementTarget?.push(data?.achiveTarget);
  });

  var options = {
    series: [
      {
        name: "Actual Target",
        type: "line",
        data: actualTarget || [],
      },
      {
        name: "Target Achievement",
        type: "column",
        data: achievementTarget || [],
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "10%",
      },
    },
    colors: ["#34C38F", "#556EE6"],
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#34C38F", "#556EE6"],
    fill: {
      colors: undefined,
      opacity: 1,
      type: "solid",
    },
    stroke: {
      width: [4, 1],
    },
    title: {
      // text: 'XYZ - Stock Analysis (2009 - 2016)',
      align: "left",
      offsetX: 110,
    },
    xaxis: {
      categories: months || [],
    },
    yaxis: [
      {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: "#34C38F",
          offsetX: -10,
        },
        labels: {
          show: false,
          style: {
            colors: "#34C38F",
          },
        },
        title: {
          // text: "Paint",
          style: {
            color: "#34C38F",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        seriesName: "Construction",
        opposite: true,
        axisTicks: {
          show: false,
          offsetX: 5,
        },
        axisBorder: {
          show: false,
          color: "#556EE6",
          offsetX: -10,
        },
        labels: {
          show: false,
          style: {
            // colors: "#556EE6",
            offsetX: 10,
          },
        },
        title: {
          // text: "Construction",
          style: {
            color: "#556EE6",
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "center",
      offsetX: 40,
    },
  };
  return (
    <>
      <Card className="brandanalytic">
        <Card.Body>
          <div className="chart-title-top-content">
            <h6 className="card-title">Target Information</h6>
          </div>

          <div>
            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={options.series}
                  type="area"
                  height={290}
                  className="apex-charts"
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default AchievementChart;
